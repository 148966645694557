import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "../../components";
import Button, { ButtonStyle } from "../../components/Button/Button";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import RolesAndPermissions, {
  RolesAndPermissionsType,
} from "../../components/RolesAndPermissions/RolesAndPermissions";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import {
  getFiraUserService,
  deteleUserService,
  deleteUserFromStoreService,
} from "../../services/user";
import { updatePermissiosnActionCreator } from "../../store/User/UserActions";
import { UpdatePermissionsType } from "../../types/Permissions";
import { FiraUserResponse, SimplePermissionItem } from "../../types/Response";
import { FiraPackage, FiraUserRole } from "../../utils/enums";
import { userHasPermission } from "../../utils/handlePermissionCheckboxSelection";
import {
  firaPermissionsDataHandler,
  permissionsGroupUnifier,
} from "../../utils/handleUserPermissions";
import { RootState } from "../../utils/types";
import styles from "./OwnerPermissionsEditView.module.scss";

export const RolesAndPermissionsContext = createContext<any>({
  userId: "",
  storeId: "",
  role: null,
  permissions: [],
});

const OwnerPermissionsEditView = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<FiraUserResponse>();
  const [mainInfo, setMainInfo] = useState<RolesAndPermissionsType>(); //Fixed
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const showAlert = usePopupAlert();

  //For Provider
  const [permissionsData, setPermissionsData] =
    useState<RolesAndPermissionsType>({
      userId: "",
      storeId: "",
      role: null,
      permissions: [],
    });

  const handleBlackButton = (steps?: number) => {
    navigate(steps ? -steps : -1);
  };

  //Se llama al servicio directamente mientras se arreglan los types de team
  const getUserData = async (id: string) => {
    const response = await getFiraUserService(id);
    setUserInfo(response.data);
  };

  const getPermissions = () => {
    if (userInfo && userInfo.permissionsStore.length > 0 && params.storeId) {
      const allPermissions = userInfo.permissionsStore;
      const storePermissions = allPermissions.find(
        (p) => p.storeId === params.storeId
      );
      if (storePermissions) {
        setMainInfo({
          userId: userInfo.id,
          storeId: storePermissions?.storeId,
          role: storePermissions.role,
          permissions: storePermissions.permissions,
          storeName: storePermissions.storeName,
          name: userInfo.username,
          userEmail: userInfo.email,
        });
        setPermissionsData({
          userId: userInfo.id,
          storeId: storePermissions?.storeId,
          role: storePermissions.role,
          permissions: storePermissions.permissions,
          storeName: storePermissions.storeName,
          name: userInfo.username,
          userEmail: userInfo.email,
        });
      }
      setIsLoading(false);
    }
  };

  const saveChanges = async () => {
    setIsLoading(true);
    let permissionModel: UpdatePermissionsType = {
      storeId: permissionsData.storeId ? permissionsData.storeId : "",
      name: permissionsData.name ? permissionsData.name : "",
      userEmail: permissionsData.userEmail ? permissionsData.userEmail : "",
      role: permissionsData.role ? permissionsData.role : FiraUserRole.OPERATOR,
      permissions:
        firaPermissionsDataHandler(
          permissionsData.role,
          permissionsData.permissions
        ) || [],
    };
    permissionModel &&
      dispatch(updatePermissiosnActionCreator(permissionModel));
  };

  const discardChanges = () => {
    setIsLoading(true);
    mainInfo && setPermissionsData(mainInfo);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const deleteUser = async () => {
    if (mainInfo && mainInfo.userId) {
      try {
        await deleteUserFromStoreService(mainInfo.userId, mainInfo.storeId);
        handleBlackButton(2);
        showAlert(AlertType.success, t("alerts.success"));
      } catch (error) {
        showAlert(AlertType.error, "Ha ocurrido un error.");
      }
    }
  };

  const handleRoleChange = (role: FiraUserRole) => {
    setPermissionsData({
      ...permissionsData,
      role: role,
    });
  };

  const handleProductChange = (product: FiraPackage) => {
    let firaProduct = {
      firaProduct: product,
      permissions: [],
    };

    if (userHasPermission(permissionsData.permissions, product)) {
      setPermissionsData({
        ...permissionsData,
        permissions: permissionsData.permissions.filter(
          (obj) => obj.firaProduct !== firaProduct.firaProduct
        ),
      });
    } else {
      setPermissionsData({
        ...permissionsData,
        permissions: [...permissionsData.permissions, firaProduct],
      });
    }
  };

  const handleModuleSelection = (
    permissions: SimplePermissionItem,
    isRemoving: boolean
  ) => {
    let data = permissionsData.permissions.find(
      (item) => item.firaProduct === permissions.firaProduct
    );

    let toFilter = [...permissionsData.permissions];

    if (data !== undefined && isRemoving) {
      const resultArray = data?.permissions.filter(
        (item) => !permissions.permissions.includes(item)
      );

      data.permissions = resultArray;
      const indexToReplace = toFilter.findIndex(
        (obj) => obj.firaProduct === data?.firaProduct
      );
      if (indexToReplace !== -1) {
        toFilter[indexToReplace] = data;
      }
      setPermissionsData({
        ...permissionsData,
        permissions: toFilter,
      });
    } else if (data !== undefined && !isRemoving) {
      //Unify
      const newArr = [...permissionsData.permissions, permissions];
      setPermissionsData({
        ...permissionsData,
        permissions: permissionsGroupUnifier(newArr),
      });
    } else {
      setPermissionsData({
        ...permissionsData,
        permissions: [...permissionsData.permissions, permissions],
      });
    }
  };

  const options = (
    <>
      <div style={{ display: "flex", gap: "4px" }}>
        <Button
          buttonStyle={ButtonStyle.border}
          width={"94px"}
          clickHandler={discardChanges}
        >
          {t("views.edit_permissions.dismiss_button")}
        </Button>
        <Button
          width={"106px"}
          clickHandler={saveChanges}
          isDisabled={isLoading || isInvalid}
        >
          {t("views.edit_permissions.save_button")}
        </Button>
      </div>
    </>
  );

  useEffect(() => {
    params.id && getUserData(params.id);
  }, [params]);

  useEffect(() => {
    getPermissions();
  }, [userInfo]);

  useEffect(() => {
    if (user.error) {
      showAlert(AlertType.error, "Ha ocurrido un error.");
      setIsLoading(false);
    }
  }, [user.error]);

  useEffect(() => {
    if (user.team.updatedUserPermissions === true) {
      navigate(-1);
    }
  }, [user.team.updatedUserPermissions]);

  useEffect(() => {
    if (permissionsData && permissionsData.permissions.length === 0) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [permissionsData]);

  return (
    <>
      <FiraNavBar
        backButtonHandler={handleBlackButton}
        titleSection={permissionsData?.storeName || ""}
        trailingOptions={options}
      />
      <div className={styles.PermissionsViewWrapper}>
        <RolesAndPermissionsContext.Provider
          value={{
            isLoading,
            setIsLoading,
            mainInfo,
            permissionsData,
            handleRoleChange,
            handleProductChange,
            handleModuleSelection,
          }}
        >
          <RolesAndPermissions />
        </RolesAndPermissionsContext.Provider>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            paddingTop: "16px",
          }}
        >
          <Button
            width="171px"
            height="32px"
            buttonStyle={ButtonStyle.delete}
            clickHandler={deleteUser}
          >
            {t("views.team.details.table.deleteUserButton")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default OwnerPermissionsEditView;
