export const TRANSLATIONS_ES = {
  auth: {
    express: {
      fira: {
        success_description:
          "Esta es la dirección web de mi sitio personal. Guárdala y compártela con tus amigos.",
        failed_description:
          "¡Oops! Parece que algo falló. Por favor, inténtalo de nuevo.",
      },
      title: "Crea tu Link único",
      success_title: "Hola ❤️",
      success_description:
        "Este es tu Link Único para que tú y tus clientes puedan ingresar al Live Shopping Mary Kay. ¡Cópialo y compártelo!",
      failed_description: "Bienvenida!!!",
      disabled: {
        title: "Estimado usuario",
        message:
          "El inicio de sesión está inactivo en este momento. Por favor, contacta el equipo administrativo para obtener más información.",
        closing: "Gracias por tu comprensión.",
      },
    },
    header: {
      welcome_back: "Bienvenido de nuevo",
      sign_up: "¡Regístrate Ahora!",
      recovery: "Restablecer contraseña",
      pwdcode: "Código de recuperación",
      changepwd: "Cambio de contraseña",
      activate: "Código de Activación",
      invitation_message:
        "La marca {referer} te ha invitado a unirte a su equipo",
    },
    tabs: {
      login: "Iniciar sesión",
      create_account: "Crear cuenta",
    },
    login: {
      message: "Las contraseñas deben tener al menos 8 caracteres.",
      user: "Usuario / Correo electrónico",
      password: "Contraseña",
      password_recovery: "Restablecer contraseña",
      button: "Ingresar",
    },
    password_recovery: {
      message: "Te enviaremos instrucciones para restablecer tu contraseña.",
      email: "Correo electrónico",
      button: "Enviar",
    },
    pwdcode: {
      message:
        "El código de recuperación fue enviado a tu correo. Por favor, introduzca el código:",
      recovery_message: "¿No te llegó el código de recupercación?",
      resend: "Reenviar",
      button: "Enviar",
    },
    changepwd: {
      new_pass: "Nueva contraseña",
      confirm_pass: "Confirmar contraseña",
      button: "Ingresar",
    },
    alerts: {
      registration_success: "Su contraseña se ha cambiado satisfactoriamente",
      activation_success: "Su cuenta se ha activado satisfactoriamente",
    },
    register: {
      name: "Nombre",
      lastname: "Apellido",
      email: "Correo Electrónico",
      username: "Usuario",
      password: "Contraseña",
      confirm_pass: "Confirmar contraseña",
      terms_and_conditions: "Al registrar su cuenta, acepta nuestros",
      terms_and_conditions_link:
        "Términos de Servicio y Política de Privacidad y Cookies.",
      button: "Crear Cuenta",
      message: "* Utiliza como mínimo 8 carácteres",
    },
    activation: {
      message:
        "El código de activación fue enviado a tu correo. Por favor, introduzca el código:",
      resend_message: "¿No te llego el codigo de activación?",
      resend: "Reenviar",
      button: "Enviar",
    },
    messages: {
      error: {
        username_not_found: "No tienes cuenta en FIRA",
        create_account: "Crear cuenta",
        email_not_found: "No tienes cuenta en FIRA",
        not_activated: "No tienes cuenta en FIRA",
        invalid_password: "Credenciales incorrectas",
        wrong_credentials: "¿Te has equivocado?",
        unavailable_user: "Usuario o correo electronico ya registrados",
        recover_password: "¿Deseas recuperar la contraseña?",
        invalid_user_code: "Código de activación incorrecto",
        try_again: "Intenta de nuevo",
        expired_user_code: "Código de activación inválido",
        request_new_code: "Solicitar uno nuevo",
        default: "Hemos encontrado un error intenta de nuevo",
      },
    },
  },
  views: {
    products: {
      title: "Productos",
      exportButton: "Exportar",
      importButton: "Importar",
      addProductButton: "Agregar Producto",
      tableHeader: {
        name: "Nombre",
        inventory: "Inventario",
        price: "Precio",
        priceWithTaxes: "Precio",
        extraPrice: "Precio",
        discount: "Descuento",
        visibility: "Visibilidad",
      },
      detailProduct: {
        newProduct: "Nuevo Producto",
        save: "Guardar",
        edit: "Editar",
        saving: "Guardando..",
        saved: "Producto guardado",
        imageError: "Error en la carga de imagen",
        error: "Hemos encontrado un error intenta de nuevo",
        discard: "Descartar",
        form: {
          name: "Nombre",
          SKU: "SKU",
          skuInfo: "Código único de inventario asignado a un producto",
          exterId: "Id Externo",
          exterIdInfo: "Código único de un producto generado por el e-comerce",
          inventory: "Inventario",
          URL: "URL",
          price1: "Precio",
          price2: "Precio 2",
          price3: "Precio 3",
          media: "Elementos multimedia",
          discount: "Precio con descuento",
          optional: "Descuento (opcional)",
          images:
            "La imagen destacada es la que aparecerá de primera en la portada del producto durante la tansmisión",
          retrieve: "Recuperar",
          addImage: "Tamaño máx 1080px x 1080px",
          description: "Descripción",
        },
        origin: "Origen",
        categoryTitle: "Categorias",
        variationOption: "Opciones de Variaciones",
        addButton: "Agregar",
        delete: "Eliminar",
        placeholderSearch: "Toca para buscar...",
        variant: "Variante",
        inventory: "Inventario",
        price1: "Precio1",
        price2: "Precio2",
        labels: "Etiquetas ",
        label: "Etiqueta",
        example: "Ej: Oferta",
        addImageVar: "Asignar Imagen a una variante",
        cancel: "Cancelar",
        ready: "Listo",
        variationName: "Nombre de la variación",
        variationValue: "Valores de variacion",
        addValue: "Agregar valor",
        fileFormat: "Formato de archivo",
        sizeRecommended: "Tamaño recomendado",
        maximumWeight: "Peso maximo del archivo",
        addVariant: "Agregar Variante",
        emptyVariant: "Todas las variaciones fueron agregadas",
        errorImages:
          "Solo se permite cargar imagenes en formato .jpg .jpeg .png",
        stores: "Tiendas",
        search: "Toca para buscar...",
      },
      visibiliy: {
        owner: "Owner",
        total: "Total",
        partial: "Parcial",
      },
    },
    events: {
      title: "Eventos",
      status: {
        broadcast: {
          scheduled: "Programado",
          started: "Iniciado",
          not_started: "No iniciado",
          cancelled: "Cancelado",
          done: "Finalizado",
          config: "Configurar",
        },
        test: {
          scheduled: "Prueba programada",
          started: "Prueba iniciada",
          not_started: "Prueba no iniciada",
          cancelled: "Prueba Cancelada",
          done: "Finalizado",
          config: "Configurar",
        },
      },
      header: {
        listButton: "Lista de Eventos",
        today: "Hoy",
        day: "Día",
        month: "Mes",
        week: "Semana",
        eventName: "Nombre Evento",
        date: "Fecha",
        startHour: "Hora inicio",
        endHour: "Hora Fin",
        products: "Productos",
        status: "Status",
        options: "Opciones",
        filter: "Toca para Buscar....",
      },
      table: {
        products: "productos",
        statusOptions: {
          scheduled: "Programado",
          initEvent: "Iniciar Evento",
          live: "Live",
          finished: "Finalizado",
          cancelled: "Cancelado",
          config: "Configurar",
        },
      },
      new_popup: {
        event_name: "Nombre del evento",
        placeholderSearch: "Buscar tienda...",
        start_time: "Fecha",
        time: "Hora",
        button_create: "Crear Live",
        button_create_test: "Crear live de prueba",
        placeholder: "DD/MM/AA",
        simulcastEvent: "Evento simultáneo",
        independentEvents: "Eventos independientes",
        infoSimulcast:
          "Transmitir un evento simultáneamente en varias tiendas, que será realizado por la cuenta principal",
        infoIndependent:
          "Programar un evento independiente simultáneamente para varias tiendas",
      },

      popup: {
        event_name: "Nombre del Evento",
        start_time: "Hora de inicio",
        end_time: "Hora final",
        button_create: "Crear Evento",
      },
      popup_top: {
        event_name: "Nombre de Live",
        start_time: "Fecha inicio",
        time: "Hora",
        button_create: "Crear Live",
        button_create_test: "Crear live de prueba",
        placeholder: "DD/MM/AA",
      },

      popup_option: {
        moderation: "Moderar",
        statistics: "Detalle Transmision",
        edit_event: "Editar",
        cancel_event: "Cancelar",
        event_canceled: "Evento cancelado",
      },
      eventDetail: {
        moderationTitle: "{{eventName}} | Moderación",
        statisticsTitle: "{{eventName}} | Estadísticas",
        credentials: "Credenciales",
        statistics: "Resumen LIVE",
        postStats: "Resumen POS-LIVE",
        moderation: "Moderación",
        likes: "Me gusta",
        shares: "Compartidas",
        connections: "Audiencia",
        averageConnectionTime: "Promedio de visualización",
        connectionTime: "Tiempo de visualización",
        bouncingRate: "Tasa de rebote",
        engagement: "Engagement",
        averageClicks: "Clicks",
        chatMessages: "Mensajes del chat",
        liveTime: "Tiempo de live",
        start: "Iniciar Live",
        finish: "Finalizar Live",
        startTest: "Iniciar prueba",
        finishTest: "Finalizar prueba",
        exportData: "Exportar",
        processing: "Procesando...",
        infoId: "Identificador único de un evento",
        messages: {
          copy_success: "¡copiado exitosamente!",
          invalid_id: "ID de Stream {streamId} inválido",
          not_started:
            "No se puede terminar una transmisión con un estado diferente de iniciado",
          no_value: "Error 500, No hay valor presente",
          other_started: "Existe otro evento iniciado",
          red5_error:
            "En este momento no se pueden generar las credenciales, inténtalo más tarde",
          cant_generate_stats:
            "Ha ocurrido un error descargando las estadísticas, por favor revise la configuración de la Tienda",
        },
        instructions: {
          title: "Inicia el live",
          instruction:
            "En caso de que la credenciales no existan darle click a credenciales",
        },
        fakeChat: {
          title: "Todavía no hay chats",
          subTitle: "Envía un mensaje para iniciar la conversación.",
          refresh: "Actualiza el chat para poder interactuar.",
          refreshButton: "Actualizar",
        },
        products: {
          title: "Ventanas Emergentes",
          messages: {
            popupError: "Sólo puedes tener 2 productos como destacados",
          },
          filter: "Toca para Buscar....",
          add: "Agregar",
          highlight: "Destacar",
        },
        chat: {
          input: "Texto..",
        },
        skeleton: {
          message:
            "Inicia la transmisión desde tu software de stream favorito y da click en “refrescar” para comenzar a ver el preview del LIVE",
          refresh: "Refrescar",
          finished: "Evento Finalizado",
        },
        users_table: {
          title: "Datos de los espectadores",
          subtitle: "Datos capturados durante el live",
          export: "Exportar",
          filter: "Toca para Buscar....",
          username: "Nombre",
          email: "Correo electrónico",
          phone: "Teléfono",
          bpaCount: "Clicks a Productos",
        },
        products_clicks: {
          title: "Clicks a productos",
          subtitle: "Número exacto de clicks por productos",
          product: "Producto",
          clicks: "Clicks",
          interactionTitle: "Clicks a producto interacción",
          interactionSubtitle: "Número exacto de clicks por productos",
          gallery: "Galería",
          highlighted: "Destacados",
          interactionTotal: "Total de interacción",
          buyTitle: "Clicks a producto intención de compra",
          buySubtitle: "Número exacto de clicks a “comprar” por producto",
          buyIntent: "Intención de compra",
        },
        player: {
          connecting: "Conectando",
          reconnecting: "Reconectando",
          finished: "Evento Finalizado",
          refresh: "Refrescar",
          text_1: "Estás presentando dificultades en la conexión,",
          text_2: " presiona el botón de refrescar ",
          text_3: "para reconectar.",
        },
        statiticsChart: {
          title: "Datos generales",
          subtitle:
            "Espacio para realizar en versus entre metricas especificas",
          likes: "Me gusta",
          shares: "Compartidas",
          connections: "Audiencia",
          messages: "Mensajes del chat",
          clicks: "Clicks",
        },
        concurrencyChart: {
          title: "Concurrencia",
          subtitle:
            "Cantidad exacta de asistencia de usuarios por minuto durante el live",
          connections: "Conexiones",
        },
        optionsDropdown: {
          likes: "Me gusta",
          shares: "Compartidas",
          clicks: "Clicks",
          messages: "Mensajes",
        },
      },
      calendar: {
        showMore: " más",
        moderate: "Moderar",
        detail: "Detalle transmisión",
        canceled: "Cancelado",
        config: "Configurar",
        modList: {
          title: "Participantes",
          roles: {
            admin: "Administrador",
            seller: "Vendedor",
            proseller: "Proseller",
            operator: "Operador",
            manager: "Administrador",
            moderator: "Moderador",
            account_owner: "Propietario de cuenta",
          },
        },
      },
    },
    users: {
      title: "Equipo",
      active: "Activos",
      inactive: "Inactivos",
      add: "Agregar miembro",
      table: {
        name: "Nombre",
        email: "Correo Electrónico",
        phone: "Teléfono",
        permissions: "Permisos",
        total: "Total",
        partial: "Parcial",
        none: "Ninguno",
        inactive: "Inactivo",
        admin: "Administrador",
      },
      messages: {
        update:
          "Se han modificado los permisos de {{firstName}} {{lastName}} satisfactoriamente",
        invitation: "Invitación enviada satisfactoriamente",
        error_user_currently: "Usuario esta actualmente en esta Tienda",
      },
    },
    edit_permissions: {
      title: "Equipo - {nameToShow}",
      dismiss_button: "Descartar",
      save_button: "Guardar",
      role: "Rol:",
      select_operator: "Selecciona el rol del operario",
      profile: "Profile",
      select_operator_profile: "Selecciona el perfil del operario",
      roles: {
        admin: "Administrador",
        seller: "Vendedor",
        proseller: "Proseller",
        operator: "Operador",
        manager: "Administrador",
        moderator: "Moderador",
        account_owner: "Propietario de cuenta",
      },
    },
    dashboard: {
      title: "Dashboard",
      infoText: "Visualiza el resumen de tus estadísticas",
      transmissionsNumber: "Cantidad de lives",
      testTransmissionsNumber: "Lives de prueba",
      transmissionsNumberDesc:
        "Cantidad de eventos realizados en el rango de fecha seleccionado",
      testTransmissionsNumberDesc:
        "Cantidad de eventos de prueba realizados en el rango de fecha seleccionado",
      transmissionsTime: "Tiempo de lives",
      transmissionsTimeDesc: "Tiempo utilizado durante tus sesiones LIVE",
      engagement: "Engagement",
      engagementDesc:
        "Porcentaje de interacción de los usuarios activos durante los LIVE. ",
      connections: "Visualizaciones",
      connectionsDesc:
        "Número de personas que visualizaron el live según dispositivo.",
      rate: "Tasa de rebote",
      rateDesc: "Porcentaje de usuarios que no interactuaron durante el LIVE. ",
      time: "Promedio de visualización",
      timeDesc: "Promedio de permanencia de los clientes en tus sesiones LIVE",
      timeVisual: "Tiempo de visualización",
      testingLives: "Lives de pruebas",
      audienceCount: "Cantidad de audiencia",
      totalAudience: "Audiencia total",
      reproductionTotal: "Total de reproducción",
      reproductionAverage: "Promedio de reproducción",
      transmissionTime: "Tiempo de transmisión",

      events: "Eventos",
      Metrics: "Metricas",
      titleTable: "Eventos recientes",
      subTitleTable: "Visualiza en este espacio los lives finalizados",
      nextsEvents: "Eventos",

      from: "Desde",
      to: "Hasta",
      noEvents: "No hay Eventos",
      showEvents: "Ver eventos",
      table: {
        name: "Nombre",
        date: "Fecha",
        products: "Productos",
        likes: "Likes",
        shares: "Share ",
        audience: "Audiencia",
      },
      MetricsSelect: {
        audience: "Audiencia",
        conversion: "Conversión",
        likes: "Likes",
        shares: "Shares",
      },
      dateRangeSelect: {
        personalized: "personalizado",
        today: "hoy",
        yesterday: "ayer",
        lastSeven: "ultimos 7 dias",
        lastThirty: "ultimos 30 dias",
        lastNinety: "ultimos 90 dias",
        lastMonth: "el mes pasado",
        lastYear: "el año pasado",
        thisWeek: "esta semana hasta la fecha",
        thisMonth: "este mes hasta la fecha",
        thisTrimester: "este trimestre hasta la fecha",
      },
      greetings: {
        goodMorning: "Buenos días",
        goodAfternoon: "Buenas tardes",
        goodEvening: "Buenas noches",
        welcome: "Bienvenido",
        toAccount: "a la cuenta",
      },

      reproductionAccum: "Tiempo total de reproducción",
    },
    dashboardOwner: {
      title: "Dashboard",
      infoText: "Su resumen de rendimiento de este mes ",
      widgetStatsStoreTitle: "Porcentaje de estadísticas",
      widgetStatsStoreSubTitle:
        "Compara y contrasta los clicks que genera cada tienda ",
      tableWidgetTitle: "Tiendas administradas",
      tableWidgteSubTitle:
        "En este espacio se podrá visualizar todas las tiendas de la cuenta",
      livesCount: "Cantidad de lives",
      transmisionCount: "Tiempo de transmisión",
      audienceTotal: "Audiencia total",
      livesTest: "Lives de pruebas",
    },
    dashboardOwnerVideos: {
      audienceTotal: "Audiencia total",
      store: "Tienda",
      audience: "Audiencia",
      viewsAverage: "Reproducciones promedio",
      viewsTotal: "Reproducciones totales",
      tableDescription:
        "En este espacio se podrá visualizar todas las tiendas de la cuenta con su audiencia y cantidad de reproducciones, promedio y total",
    },

    firaTv: {
      title: "Widgets",
      settings: "Configuración",
      sectionTitle: "Título de la sección",
      nextEvents: "Próximos eventos",
      pastEvents: "Eventos anteriores",
      selectWidget: "Selecciona un widget de la lista",
      selectEventType: "Seleccionar los videos a mostrar",
      selectEventColors: "Selecciona el color",
      copyCode: "Copia el siguiente código en tu página web",
      copy: "Copiar",
      desktop: "Vista desktop",
      mobile: "Vista mobile",
      widgetSelectionView: {
        title: "Configuración @widget",
        searchbar: "Toca para Buscar...",
        cards: "Cards",
        mosaic: "Mosaico",
        banners: "Banners",
        collections: "Colecciones",
        carousel: "Carrusel",
        calendar: "Calendario",
        scheduled: "Programado",
        thumbnails: {
          collectionCarousel: "Colección carrusel",
          comingSoon: "Próximamente",
          collectionCard: "Colección card",
          vodGallery: "Galería de VOD",
          bannerCommercial: "Banner comercial",
          bannerSlider: "Banner slider",
          bannerLive: "Banner superior - en vivo o VOD",
          categories: "Categorías",
        },
      },
      widgets: {
        cards: {
          COLLECTION_CARDS: {
            primaryTextColor:
              "Próximos eventos, nombre del evento, descripción",
            secondaryTextColor: "Fecha y hora, “ver más”",
            buttonTextColor: "Texto botón",
            primaryColor: "Detalles y botón",
            cardColor: "Tarjetas de evento",
            backgroundColor: "Fondo",
            infoCardDescription:
              "Utiliza este widget para presentar a tu audiencia una colección de videos, con información acerca del momento en que fueron realizados y una breve descripción de cada uno.",
            title: "Colección card",
          },
        },
        scheduled: {
          COMING_SOON: {
            title: "Próximamente",
            infoCardDescription:
              "Utiliza este widget para informar a tu audiencia acerca de uno de los eventos programados, incluyendo el tiempo restante hasta su inicio, y ofrecerles la opción de recibir notificaciones en el momento de su inicio.",
            primaryTextColor: "Nombre del evento, descripción, temporizador",
            displayColor: "Display",
            buttonTextColor: "Texto botón",
            primaryColor: "Detalles y botón",
            backgroundColor: "Fondo",
          },
        },
        carousel: {
          COLLECTION_CAROUSEL: {
            title: "Colección carrusel",
            infoCardDescription:
              "Utiliza este widget para destacar los eventos más relevantes de tus colecciones ante tu audiencia. Con este widget podrás mostrar la categoría, el nombre del evento y la fecha en que tuvo lugar. Se mostrarán dos eventos a la vez.",
            primaryTextColor: "Eventos destacados",
            displayColor: "Display",
            primaryColor: "Detalles",
            backgroundColor: "Fondo",
          },
        },
        grid: {
          VOD_GALLERY: {
            title: "galería de VOD",
            infoCardDescription:
              "Utiliza este widget para mostrar a tu audiencia los videos almacenados en la biblioteca de colecciones. Además, este widget puede servir como página de redireccionamiento en combinación con otros widgets que también redirijan a colecciones. Con este widget, se pueden cambiar la organización y los filtros de los eventos.",
            primaryTextColor: '"FILTROS"',
            secondaryTextColor: 'Checkbox, "ver más"',
            tertiaryTextColor: "Nombre del filtro",
            primaryColor: "Texto de las categorias",
            secondaryColor: "# de videos en categoría",
            tertiaryColor: "Fondo del botón de ordenar",
            quaternaryColor: "Orden seleccionado",
            quinaryColor: "Orden no seleccionado",
            backgroundColor: "Fondo",
          },
        },
        banners: {
          SLIDER: {
            title: "Banner slider",
            infoCardDescription:
              "Utiliza este widget de banner deslizante para exhibir en la página principal los eventos más actuales.",
            primaryTextColor: "Nombre de la colección",
            secondaryTextColor: "Nombre de la categoría",
            tertiaryTextColor: "Color del texto del botón",
            primaryColor: "Color del botón",
            secondaryColor: "Color de slide activo y color de flechas",
            tertiaryColor: "Color de fondo de flechas",
            quaternaryColor: "Color de slide inactivo",
          },
          COMMERCIAL: {
            title: "Banner comercial",
            infoCardDescription:
              "Utiliza este widget para presentar a tu audiencia un evento destacado o dirigirlos hacia una colección específica.",
            inputTitle: "VIDEO ASOCIADO AL BANNER",
          },
          TOP: {
            title: "banner superior - en vivo o VOD",
            infoCardDescription:
              "Utiliza este widget para mantener a tus usuarios actualizados sobre los videos en vivo o un video en particular que hayas seleccionado.",
          },
        },
        collections: {
          CATEGORIES: {
            title: "Categorías",
            infoCardDescription:
              "Utiliza este widget para mostrar a tu audiencia las diversas categorías de eventos que han sido transmitidos. En la página principal, se mostrarán con una imagen representativa y el nombre de la categoría.",
          },
        },
      },
      exampleItems: {
        eventName: "Nombre del evento",
        eventDescription: "Descripción del evento",
        fakeDate: "lunes, diciembre de 2022",
        titlePlaceholder: "Título de la sección",
      },
    },
    videos: {
      route: "Videos",
      title: "Galería de videos",
      table: {
        name: "Video",
        type: "Tipo",
        visibility: "Visibilidad",
        date: "Fecha de transmisión",
        category: "Categoría",
        options: {
          hide: "Oculto",
          visible: "Visible",
        },
        state: {
          processing: "Procesando...",
          processed: "Procesado",
          failed: "Fallido",
        },
      },
      filter: "Toca para Buscar...",
      filterOptions: {
        recent: "Más reciente",
        older: "Más antiguo",
        viewed: "Más vistas",
        alphabet: "de la A a la Z",
        reverse: " de la Z a la A",
        like: "Más likes",
        share: "Más compartidos",
      },
      steps: {
        generalData: "Datos Generales",
        video: "Video",
        products: "Productos",
        comments: "Comentarios",
        next: "Siguiente",
        end: "Finalizar",
      },
      editVideo: {
        general_details: {
          title: "Información general",
          message:
            "Completa la siguiente información relacionada con tu evento",
          forms: {
            event_name: "Nombre del video",
            datetime: "Fecha y hora",
            desc: "Descripción del video",
            select_categories: "Seleccionar categorías",
            load_teaser: "Cargar teaser",
            load_image: "Cargar imagen",
            optional: "(Opcional)",
            teaser_info: "Video con avance del evento",
            file_format: "Formato de archivo",
            recommended_size: "Tamaño recomendado",
            image_info: "Imagen de portada para su evento",
            placeholder: "Toca para Buscar...",
          },
        },
      },
      errors: {
        category: "No se pueden eliminar todas las categorías",
        stats: "Ha ocurrido un error en la descarga de las estadísticas.",
      },
      labels: {
        titleStats: "Datos generales",
        subtitleStats:
          "Espacio para realizar una comparación entre métricas específicas",
        current: "Hoy",
        yesterday: "Ayer",
        lastWeek: "Última semana",
        lastTwoWeeks: "Últimas dos semanas",
        lastMonth: "Último mes",
        lastTrimester: "Último trimestre",
        lastSemester: "Último semestre",
        lastYear: "Último año",
        yourStores: "Tus tiendas",
        titlePieChart: "Porcentaje de estadísticas",
        subtitlePieChart:
          "Compara los siguientes datos entre cada una de las tiendas.",
      },
      selects: {
        filter: "Filtros",
      },
      productList: {
        title: "Productos estrellas",
        subtitle: "Productos con mayor interacción por tienda",
      },
      previewLink: "Previsualización",
    },
    collections: {
      title: "Colecciones",
      titlePage: "Colecciones de videos",
      createCollection: "Crear colección",
      table: {
        name: "Nombre de la colección",
        date: "Fecha de creación",
        videos: "Videos",
        id: "ID de la colección",
      },
      details: {
        newTitle: "Nueva colección",
        discard: "Descartar",
        save: "Guardar",
        collectionName: "Nombre de la colección",
        description: "Descripción",
        cover: "Imagen de portada para tu colección",
        listTitle: "Videos de la colección",
        novideos:
          "No hay videos en esta colección,\n busca o explora para agregar videos.",
        table: {
          name: "Nombre",
          desc: "Descripcion",
          teaser: "Teaser",
          image: "Imagen",
          products: "Productos",
          addButton: "Agregar",
        },
        delete: "Eliminar colección",
        deleteAlert: "¿Deseas eliminar {{collectionName}}?",
        deleteMessage: "Esta acción no puede deshacerse.",
        cancel: "Cancelar",
        saved: "Colección guardada exitosamente",
        discardAlert: "¿Desea guardar antes de abandonar la página?",
        discardMessage:
          "Al salir de esta página, se eliminarán todos los cambios sin guardar.",
        leavePageButton: "Abandonar página",
        saveAndLeave: "Guardar y salir",
        error: "Ha ocurrido un error. Por favor intente de nuevo",
      },
    },
    stores: {
      title: "Tiendas",
      createBtn: "Crear tienda",
      importBtn: "Importar",
      exportBtn: "Exportar",
      table: {
        storeName: "Nombre",
        status: "Estado",
        storeId: "ID de la tienda",
        detailsBtn: "Ver detalles",
        accessBtn: "Ingresar",
        role: "Rol",
        roleTypeAdmin: "Administrador",
        roleTypeSeller: "Vendedor",
      },
      status: {
        onhold: "En espera",
        active: "Activo",
        suspended: "Suspendido",
        canceled: "Cancelado",
        done: "Completado",
        deactivated: "Inactivo",
        deleted: "Eliminado",
        approved: "Aprobado",
        pending: "Pendiente",
        rejected: "Rechazado",
        inprocess: "En proceso",
        valid: "Válido",
        invalid: "Inválido",
      },
      createSuccess: "¡Has creado la tienda exitosamente!",
      details: {
        accessBtn: "Ingresar a tienda",
        createdDate: "Creada el",
        contact: "Contacto",
        country: "País",
        currency: "Moneda",
        city: "Ciudad",
        website: "Página web",
        editBtn: "Editar información",
        transferBtn: "Transferir propiedad",
        deleteBtn: "Eliminar tienda",
        table: {
          name: "Nombre Operador",
          status: "Estado",
          role: "Rol",
          operatorId: "ID del operario",
          seeDetails: "Ver detalles",
          roles: {
            admin: "Administrador",
            seller: "Vendedor",
            proseller: "ProSeller",
            operator: "Operador",
            manager: "Manager",
            moderator: "Moderador",
            account_owner: "Account Owner",
          },
        },
      },
      error: "Ha ocurrido un error. Por favor, intente de nuevo",
      storeDeleteSuccess: "¡La tienda se eliminó con éxito!",
    },
    team: {
      title: "Equipo",
      invites: "Invitaciones",
      name: "Nombre",
      status: "Estado",
      seeProfileBtn: "Ver perfil",
      teamMember: "Crear operario",
      inviteMember: "Invitar operario",
      details: {
        title: "Tiendas",
        table: {
          storeName: "Nombre de Tienda",
          role: "Rol",
          lastAccess: "Último ingreso",
          permissions: "Ver permisos",
          deleteUserButton: "Eliminar operario",
        },
      },
    },
    ownerCalendar: {
      title: "Calendario / Eventos",
    },
    operators: {
      title: "Añadir a un nuevo operario",
      invitation_title: "Información del operario",
      invitation_desc:
        "Completa los datos generales de tu nuevo operario para continuar con la operación.",
      nextBtn: "Siguiente",
      prevBtn: "Regresar",
      createBtn: "Crear",
      inviteBtn: "Invitar",
      name: "Nombre del operario",
      email: "Correo electrónico",
      operator_role: "Rol de operario",
      operator_role_desc:
        "Elige el rol que ocupará el operario que estás creando.",
      operator_name: "Operario",
      operator_desc:
        "Este perfil tendrá limitaciones de funciones dentro del servicio, de acuerdo al nivel que se le asigne, deberá tener un supervisor el cual podrá ver o modificar sus acciones.",
      operator_profile: "Perfil: Operario",
      operator_profile_desc:
        "Selecciona los módulos a los que quieres que este usuario acceda",
      admin_name: "Administrador",
      admin_desc:
        "Este perfil se encargará de administrar la tienda a su cargo, está a cargo de gestionar los lives que realice. ",
      admin_profile: "Perfil: Administrador",
      admin_profile_desc:
        "Escoge el servicio en el cual será asignado el Administrador",
      moderator_name: "Moderador",
      moderator_desc:
        "Este perfil se encargará de interactuar en el chat de los lives a los que sea asignado, tendrá manejos de mensajes, pop ups, entre otras funciones determinadas para un live.",
      moderator_profile: "Perfil: Moderador",
      moderator_profile_desc:
        "Escoge el servicio en el cual será asignado el Moderador",
      error: "¡No se cargaron los datos, intente nuevamente!",
      duplicated_invitation: "El usuario tiene una invitación pendiente",
      himself_invitation: "No puedes hacer una invitación a ti mismo",
      already_invited: "El usuario ya pertenece a la tienda",
      success: "Invitación enviada al correo: ",
    },
    landing: {
      title: "Fira Onlive",
      tag: "Nuevo",
      subLinks: {
        config: "Configuración",
        landing: "Landing",
        preview: "Vista previa",
      },
      backBtn: "Atrás",
      saveBtn: "Guardar",
      createBtn: "Crear",
      editBtn: "Editar URL",
      infoLineOne: "Edita y personaliza la terminación de tu URL.",
      infoLineTwo: "Ej: firaonlive.com/ejemplourl",
      storeName: "NombreDeLaLanding",
      page: {
        title: "Página",
        headerHeight: "Altura del header en px",
        colorStyle: "Estilo del color",
        background: "Fondo",
        header: "Cabecera",
        footer: "Pie de página",
        logoAlignment: "Alineación del logo",
        left: "Izquierda",
        center: "Centro",
        shoppingCart: "Carrito de compras",
        shoppingCartSwitch: "Agregar carrito de compras",
      },
      widgets: {
        title: "Widgets",
        widgetStyle: "Estilo del widget",
        titleLabel: "Título",
        screen: "Pantalla",
        details: "Detalles",
        background: "Fondo",
        widgetsDesc: "Seleccionar eventos a mostrar",
        collection: "Colección asociada al widget",
        collectionPlaceholder: "Nombre de la colección",
        collectionError: "Debes seleccionar una colección para tu widget",
        selectWidget: "Seleccionar widget",
        featured: "Lives destacados",
        featuredInfo: "Al activar este widget, se mostraran una serie de VOD.",
        featuredProducts: "Productos destacados",
        featuredProductsInfo:
          "Para usar este widget, el carrito de compras debe estar activado.",
        products: "Productos",
        widgetTitle: "Título del widget",
        searchInput: "Toca para buscar...",
        widgetProductsErrorV2:
          "Debes seleccionar al menos un producto para el widget de productos destacados.",
      },
      typography: {
        title: "Tipografía",
        body: "Contenido",
        footer: "Pie de página",
      },
      catalog: {
        title: "Catálogo",
        moduleTitle: "Catálogo en línea",
        toggleLabel: "Activar catálogo de productos",
        products: "Productos",
        desc: "Selecciona los productos que van a  aparecer en la sección de catálogo.",
        all: "Seleccionar todo",
      },
      createSuccess: "¡Creaste la landing page exitosamente!",
      createError: "¡Ups! Parece que la URL ya existe",
      updateSuccess: "¡Has editado la landing page exitosamente!",
      updateError: "Ocurrió un error, por favor intenta de nuevo",
      phoneError: "Por favor ingresa un número de teléfono válido",
      popUp:
        "No has configurado tu landing. Clickea el botón de configuración para terminar el proceso.",
    },
  },
  components: {
    chat: {
      headerChatText: "Chat",
      headerUserText: "Usuarios",
      inputPlaceholder: "Texto",
      deleteMenuButton: "Eliminar",
      banMenuButton: "Bloquear usuario",
      unbanMenuButton: "Desbloquear",
      shadowBanMenuButton: "Silenciar usuario",
      removeShadowBaneMenuButton: "Quitar Silencio",
      pinMessageMenuButton: "Anclar mensaje",
      unpinMessageMenuButton: "Desanclar mensaje",
      noSearch: "Ingresa el nombre del usuario",
      noUsers: "No se encontraron usuarios",
      loading: "Cargando...",
      searchPlaceholder: "Toca para buscar...",
      message: "Escribe un mensaje...",
      sendButton: "Enviar",
      noStarted: "El evento no ha comenzado",
      info: "Información registrada",
      detailsButton: "Detalles del usuario",
      hide: "Ocultar",
      copy: "¡Copiado!",
      noData: "No hay datos",
    },
    table: {
      counterText: "Seleccionados",
      optionsButton: "Eliminar",
      searchPlaceHolder: "Toca para Buscar....",
      filteringTitle: "Ordenar:",
    },
    navBar: {
      contextMenu: {
        changeBrandButton: "Cambiar de marca",
        storeProfileButton: "Perfil de la Tienda",
        userProfileButton: "Perfil Personal",
        invitationsButton: "Mis Invitaciones",
      },
      logoutButton: "Cerrar Sesión",
      settingButton: "Configuración",
      accountConfig: {
        title: "Configuración de cuenta",
        syncTitle: "Sincronización",
      },
      storeSettingButton: "General",
      storeInfoButton: "Información de tienda",
      profileSettingsButton: "Perfil Personal",
      userSecurityButton: "Seguridad",
      profileRolesAndPermissions: "Roles y permisos",
    },
    synchronization: {
      title: "Sincronización con Ecommerce",
      unsunc: "Desincronizar",
      subtitle: "Agregar e-comerce",
      description:
        "Escoge la plataforma e-comerce con la cual deseas hacer la sincronización",
      loading: "Procesando...",
      success: "¡La sincronización ha sido exitosa!",
      failed: "¡Fallo en la sincronización!. ",
      retry: "Vuelve a intentar",
    },
    videoSettings: {
      title: "Configuración de video",
      subtitle: "Reemplaza tu video en el caso de requerir algún ajuste",
      replaceVideo: "Reemplazar video",
      editVideo: "Editar video",
      errorVideo: "Previsualización no disponible",
      popUpDecision: {
        title: "¿Estás seguro de que quieres reemplazar el video?",
        message: "Recuerda que esta acción sólo la podrás realizar una vez",
      },
      popUpError: {
        title: "¡No se pudo completar la carga!",
        message: "Es necesario volver a subir el archivo.",
        retry: "Reintentar",
      },
      popUpFormatError: {
        title: "El formato del archivo no es compatible",
        message: "Recuerda que debes subir el archivo en formato ",
      },
      popUpLoading: {
        title: "Subiendo video",
        loading: "Cargando...",
      },
      popUpSizeError: {
        title: "Tamaño de archivo demasiado grande",
        description: "Recuerda que el video no puede pesar más de ",
      },
      popUpOnlyOneMp4Error: {
        title: "Hubo un error al reemplazar el video.",
        description: "Solo puedes seleccionar un archivo .mp4.",
      },
      popUpTS: {
        title: "Hubo un error al reemplazar el video.",
        description:
          "Por favor, selecciona al menos un archivo .m3u8 y al menos un archivo .ts, o solo un archivo .mp4.",
      },
    },
    imageUpload: {
      load: "Cargar imagen",
      fileFormat: "Formato de archivo",
      fileSize: "Tamaño recomendado",
    },
    editProductsTable: {
      table: {
        name: "Nombre",
        sku: "SKU",
        quantity: "Inventario",
        price: "Precio",
        discount: "Descuento",
        entry: "Ingreso",
      },
      popup: {
        header: {
          hideProducts: "Ocultar todos los productos",
          showProducts: "Mostrar todos los productos",
          hidePrices: "Ocultar todos los precios",
          showPrices: "Mostrar todos los precios",
        },
        rows: {
          hideProduct: "Ocultar producto",
          showProduct: "Mostrar producto",
          hidePrices: "Ocultar precios",
          showPrices: "Mostrar precios",
        },
      },
      errors: {
        emptyInput: "El campo no puede quedar vacio",
      },
    },
    infoCard: {
      title: "Descripción del funcionamiento",
      NEXT_EVENTS_CARROUSEL:
        "Use this widget to present your audience with a collection of videos, with information about when they were made and a brief description of each one.",
    },
    widgetConfigCard: {
      title: "General",
      sectionTitle: "Estilo de widget",
    },
    selectEventsWidget: {
      title: "Seleccionar eventos a mostrar",
      eventTypes: "Tipos de eventos",
      nextEvents: "Eventos próximos",
      prevEvents: "Eventos anteriores",
      collections: "Colecciones",
    },
    selectByNameWidget: {
      eventTitle: "Evento asociado al widget",
      collectionTitle: "Colección asociada al widget",
      select: "Seleccionar",
      optional: "(opcional)",
    },
    selectByCollectionNameWidget: {
      collectionTitle: "Colección asociada al widget",
      defaultv1: "Nombre de la colección",
      multiple: "Colecciones asociadas al widget MAX 8 ",
    },
    selectByEventNameWidget: {
      title: "Video asociado con el banner",
      defaultv1: "Nombre del evento",
    },
    widgetRadioButton: {
      videos: "Videos guardados",
      collections: "Colecciones",
      videoTitle: "Video asociado al banner",
    },
    widgetImageInput: {
      load: "Cargar imagen",
    },
    widgetUrlInput: {
      title: "Página de redireccionamiento",
    },
    statusSelect: {
      active: "Activo",
      inactive: "Inactivo",
    },
    permissionCard: {
      title: "Permisos:",
      edit: "Editar Permisos",
    },
    settings: {
      sidebar: {
        modules: "Módulos",
        events: "Eventos",
        landing: "Landing",
        product: "Producto",
        additionals: "Variables Adicionales",
        custom: "Datos adicionales",
      },
      additionals: {
        title: "Variables Adicionales de Tienda",
        subtitle:
          "Estas son variables que se usarán para la construcción de la URL de los productos y de la Landing de cada tienda.",
      },
      product: {
        title: "Configuración global de productos",
        subtitle: "Permisos",
        canCreate: "Las tiendas pueden",
        canEdit: "Las tiendas pueden",
        canCreateCon: "nuevos productos",
        canEditCon: "detalles de productos",
        create: "cargar",
        edit: "editar",
      },
      event: {
        title: "Configuración global de eventos",
        tabs: {
          permissions: "Permisos",
          graphicConfig: "Configuración gráfica",
        },
        permissions: {
          description:
            "Usar la configuración predeterminada por el Owner para todas las tiendas seleccionadas",
        },
        extra: {
          title: "Opciones globales",
          globalCounters: {
            label: "Los eventos mostraran contadores globales",
            tooltip:
              "Al activar esta opción, verás la suma total de visualizaciones todos los eventos simultáneos en lugar de las vistas individuales de cada usuario.",
          },
        },
      },
      landing: {
        title: "Configuración global de landing",
        one: "Dale click al botón para empezar a configurar tu página",
        permissions: {
          blocker: "Hacer el paso 1 primero",
          useDefault:
            "Aplicar las configuraciones de la cuenta a todas las tiendas seleccionadas.",
          widgetDefault:
            "Este permiso bloquea la configuración de los widgets y del carro de compra.",
        },
      },
      userDetails: {
        success: "Datos guardados correctamente.",
        error: "Ha ocurrido un error, intente nuevamente.",
      },
      userRolesAndPermissions: {
        permissionsTable: {
          title: "Rol y permisos",
          storeName: "Nombre de Tienda",
          rol: "Rol",
          lastLog: "Último ingreso",
          seePermission: "Ver permisos",
          orderOptions: {
            recent: "Más reciente",
            older: "Más antiguo",
            alphabet: "de la A a la Z",
            reverse: " de la Z a la A",
          },
        },
        permissionsDetail: {
          title: "@ Nombre de la tienda",
          subTitle: "Servicios a los que tienes acceso",
          permissionsDescription:
            "Tendrá solo permisos para utilizar las funciones de este servicio",
          permissionsDescriptionv2:
            "Tendrá permisos para utilizar las siguientes funciones",
          permissionsDescriptionv3:
            "Tendrá permisos para utilizar las funciones de este servicio",
          manager: {
            title: "Manager web",
            titlev2: "Manager",
            dashboard: "Dashboard",
            events: "Eventos",
            eventsv2: "Ver eventos",
            products: "Productos",
            team: "Equipo",
          },
          proseller: {
            title: "App proseller",
            proseller: "Proseller",
            products: "Gestión de productos",
            events: "Gestión de eventos",
            moderation: "Moderación",
            statistics: "Estadísticas",
            vod: "Gestión de videos grabados",
          },
          firaTV: {
            title: "Fira TV",
            moderation: "Moderación de comentarios",
            VOD_mgmt: "Gestión de VOD",
            widgets: "Creación de widgets",
            statistics: "Estadísticas",
            collections: "Gestión de Colecciones",
          },
          dashboard: {
            title: "Dashboard",
            statistics: "Estadísticas",
          },
          events: {
            title: "Eventos",
            event_mgmt: "Gestión de eventos",
            moderation: "Moderación",
            statistics: "Estadísticas",
          },
          products: {
            title: "Productos",
            create: "Crear productos",
            batch_load: "Cargues masivos",
            edit_products: "Editar productos",
            delete_products: "Eliminar productos",
            management: "Gestión de productos",
          },
        },
      },
    },
    userSecurity: {
      title: "Seguridad",
      currentPwd: "Contraseña actual",
      email: "Correo electrónico",
      changePwd: "Cambiar contraseña",
      lastChange: "Último cambio el",
      forgotPwd: "He olvidado mi contraseña",
      forgotPwdv2: "¿Has olvidado tu contraseña?",
      newPwd: "Nueva contraseña",
      confirmPwd: "Confirmar contraseña",
      securityBar: "Fortaleza de contraseña", //CHEQUEAR CON ZAMIR
      cancelButton: "Cancelar",
      saveButton: "Guardar",
      noMatch: "Las contraseñas no coinciden",
      wrongPwd: "Las credenciales no coinciden",
    },
    createStoreForm: {
      title: "Crear nueva tienda",
      formTitle: "Información de la tienda",
      name: "Nombre de la tienda",
      url: "URL de la página web",
      language: "Idioma",
      currency: "Divisa",
      cancel: "Cancelar",
      create: "Crear tienda",
    },
    selects: {
      contextChange: {
        yourAccount: "Tu cuenta owner",
      },
      storeTypes: {
        TEXTILE: "Textil",
        ELECTRONICS: "Electrónica",
        VARIOUS: "Varios",
        COSMETICS: "Cosméticos",
      },
      currencies: {
        USD: "Dólar estadounidense",
        EUR: "Euro",
        PEN: "Nuevo Sol peruano",
        BRL: "Real brasileño",
        MXN: "Peso mexicano",
        COP: "Peso colombiano",
        CLP: "Peso chileno",
        ARS: "Peso argentino",
        GTQ: "Quetzal guatemalteco",
        NIO: "Córdoba nicaragüense",
        CRC: "Colón costarricense",
        HNL: "Lempira hondureño",
        SVC: "Colón salvadoreño",
        CLF: "Unidad de Fomento",
        BOB: "Boliviano",
        PYG: "Guaraní paraguayo",
      },
      unitSystems: {
        METRIC: "Sistema métrico",
        IMPERIAL: "Sistema imperial",
      },
    },
    store_settings: {
      general_info: {
        title: "Información general",
        store_name: "Nombre de la tienda",
        company: "Razón social de la empresa",
        store_type: "Industria de la tienda",
        language: "Idioma",
        country: "País",
        city: "Ciudad",
        address: "Dirección",
        zip_code: "Zip code",
        currency: "La moneda en la que se venden tus productos",
        time_zone: "Huso horario",
        unit_system: "Sistema de unidades",
        weight_unit: "Unidades de peso predeterminadas",
        description: "Descripción de la tienda",
      },
      brand_identity: {
        title: "Identidad de marca",
        brand_logo: "Logo de la marca",
        brand_favicon: "Fav Icon",
        typography: "Tipografía",
        brand_colors: "Colores de marca",
        main_color: "Color principal",
        secondary_color: "Color secundario",
      },
      contact_info: {
        title: "Información de contacto",
        email: "Correo electrónico",
        phoneNumber: "Número de teléfono",
        web: "URL página web",
      },
      massification: {
        title: "Masificación",
        label: "Visibilizar el último live de manera automática",
      },
      cancel_btn: "Cancelar",
      save_btn: "Guardar",
      next_btn: "Siguiente",
      prev_btn: "Regresar",
      error: "Ha ocurrido un error, intente nuevamente.",
      success: "¡La tienda se ha editado correctamente!",
    },
    inputMedia: {
      loadImg: "Cargar imagen",
      loadVideo: "Cargar video",
      formats: "Formatos: ",
      sizes: "Tamaño recomendado ",
    },
    typeSelect: {
      title: "Tipos de eventos",
    },
  },
  modals: {
    pickStore: {
      title: "SELECCIONE UNA TIENDA",
      addStoreButton: "Agregar una tienda",
    },
    profile: {
      edit_profile_modal: {
        title: "Perfil Personal",
        firstName: "Nombre",
        lastName: "Apellido",
        birthdate: "Fecha de nacimiento",
        gender: "Género",
        gender_radio: {
          male: "M",
          female: "F",
          other: "Otro",
        },
        gender_option: {
          male: "Masculino",
          female: "Femenino",
          other: "Otro",
        },
        mobilephone: "Número de teléfono",
        email: "Correo electrónico",
        email_short: "Correo",
        username: "Usuario",
        password: "Contraseña",
        repeatPassword: "Repetir contraseña",
        cancel: "Cancelar",
        submit: "Guardar",
        edit: "Editar",
        alert_success: "La información se ha guardado satisfactoriamente.",
        alert_error: "Ha ocurrido un error, intente nuevamente.",
        avatar: "Foto de perfil",
        day: "DD",
        month: "MM",
        year: "YYYY",
        selectPlaceholder: "Seleccionar",
        numberPlaceholder: "000-000-00",
        codePlaceholder: "+234",
      },
    },
    products: {
      load_modal: {
        title: "Importar productos con EXCEL",
        message_1: "Descargar",
        message_2: "Plantilla EXCEL",
        message_3: "de ejemplo del formato requerido",
        drop_zone: "Agregar archivo",
        cancel_button: "Cancelar",
        load_button: "Subir y continuar",
      },
      load_data_modal: {
        title: "Estado del Cargue",
        state: "Estado",
        quantity: "Cantidad",
        valid: "Válidos",
        duplicated: "Duplicados",
        rejected: "Rechazados",
        cancel_button: "Cancelar",
        save_button: "Guardar",
        rejected_file: "Productos Rechazados",
        duplicated_file: "Products Duplicados",
        approved_file: "Products Aprobados",
        download_file: "Descargar",
        info: "Son los productos que tienen algún error en la plantilla excel",
      },
    },
    invitations: {
      title: "Invitaciones Pendientes",
      invitation:
        "La Tienda {{store}} te está invitando a hacer parte de su equipo de trabajo",
      no_invitation: "No cuentas con invitaciones pendientes",
      accept: "Aceptar",
      dismiss: "Rechazar",
      table: {
        store: "Tienda",
        invitation: "Invitación",
        accept: "Aceptar",
        yes: "Si",
        no: "No",
      },
      messages: {
        accepted: "Ahora formas parte del equipo de {{store}}",
        rejected: "Has rechazado la invitación de {{store}}",
        error: "Ha ocurrido un error. Intente de nuevo más tarde",
      },
    },
    success_message: {
      title: "¡Has creado tu evento con éxito!",
      edited: "¡Has actualizado tu evento con éxito!",
      description1: " Hemos enviado a tu correo todos los detalles del evento ",
      description2: " para el día ",
    },
    eventDetail: {
      addProducts: {
        title: "Productos seleccionados",
        filter: "Toca para buscar....",
        table: {
          name: "Nombre",
          sku: "SKU",
          inventory: "Inventario",
          price: "Precio",
          discount: "Descuento",
        },
        add: "Agregar",
        cancel: "Cancelar",
        messages: {
          error_generic:
            "Se ha producido un error al añadir los productos seleccionados",
          error_1:
            "No fue posible encontrar la instancia bajo el id {{eventId}} y el estado Iniciado",
          error_2:
            "No se encontraron instancias de BroadcastProduct con el id {{productId}}",
          error_3: "No se ha añadido ningún producto, ya todos existen",
          error_4:
            "No se ha podido encontrar la instancia con el id {{eventId}} y la estado Agendado",
        },
      },
      deleteProducts: {
        title: "Eliminar producto",
        message: "¿Estás seguro(a) que quieres eliminar {{name}}?",
        yes: "Sí",
        no: "No",
      },
      credentials: {
        title: "Configuración de transmisión",
        subTitle:
          "Copia y pega los siguientes códigos en tu plataforma de transmisión",
        integration: {
          title: "Configuración de integración",
          instruction_1:
            "Copia y pega el siguiente código en la sección de tu página donde desees que aparezca el botón para dirigir al “Live”",
          instruction_2:
            "Copia y pega el siguiente script al final de tu página para la ejecución de la librería de Fira",
        },
        credentials: {
          title: "Credenciales de transmisión",
          generate: "Generar",
          server: "Servidor",
          key: "Clave",
          messages: {
            copied: "¡Copiado!",
            success: "Credenciales creadas exitosamente",
          },
        },
        messages: {
          invalid_id: "ID de Stream {{streamId}} inválido",
          error_saving: "Error al guardar las credenciales de transmisión",
          cant_generate:
            "No se pueden generar credenciales para un evento no agendado",
        },
      },
      start: {
        title: "Iniciar transmisión",
        message_1:
          "Antes de comenzar el evento, asegúrate de estar transmitiendo. Si inicias el evento sin señal de video, no será posible grabarlo.",
        message_2: "¿Estás listo para comenzar el evento ahora?",
        yes: "Sí",
        no: "No",
      },
      finish: {
        title: "Finalizar transmisión",
        message: "¿Estás seguro(a) que quieres finalizar el live?",
        yes: "Sí",
        no: "No",
      },
    },
    unsync: {
      title: "Desincronizar @ecommerce",
      content:
        "¿Estás seguro que quieres desincronizar la ecommcerce @ecommerce?",
      yes: "Si",
      no: "No",
    },
    insertVideo: {
      title: "Insertar vídeo",
      subtitle: "OPCIONES DE INSERCIÓN",
      option: "Mostrar los controles del reproductor.",
      cancel: "Cancelar",
      copy: "Copiar",
      messages: {
        copied: "¡Copiado satisfactoriamente!",
        error: "Ha ocurrido un error al copiar. Intenta de nuevo",
      },
    },
    videosList: {
      title: "Videos seleccionados",
      table: {
        videoName: "Nombre del video",
        desc: "Descripción",
        teaser: "Teaser",
        products: "Productos",
      },
      abandon: "Abandonar página",
      save: "Guardar y salir",
    },
    storeDetails: {
      title: "¿Estás seguro de que deseas eliminar la tienda?",
      desc: "Ten en cuenta que esto no se podrá deshacer.",
      cancel: "Cancelar",
      accept: "Aceptar",
    },
  },

  dialogs: {
    deleteProducts: {
      title: "¡Atención!",
      singleTextContent: "¿Seguro(a) que deseas eliminar {productName}?",
      multipleTextContent:
        "¿Seguro(a) que deseas eliminar {quantity} productos?",
      textContent: "Esta acción no puede deshacerse.",
      deleteButton: "Eliminar",
    },
    cancelButton: "Cancelar",
  },
  store: {
    edit_store_modal: {
      title_create: "Agregar Nueva Tienda",
      title: "Perfil de Tienda",
      store_name: "Nombre de la tienda",
      store_id: "ID de la tienda",
      name: "Nombre",
      desc: "Descripción",
      desc_2: "Descripción de la tienda",
      lang: "Idioma",
      currency: "Moneda",
      state: "Estado",
      city: "Ciudad",
      country: "País",
      postCode: "Código Postal",
      address: "Dirección",
      save: "Guardar",
      cancel: "Cancelar",
      edit: "Editar",
      alert_success: "La información se ha guardado satisfactoriamente",
      alert_success_create: "Su tienda {name} se ha creado satisfactoriamente",
    },
    brand_identity: {
      title: "Identidad de marca",
      edit: "Editar",
      brand_logo: "Logo de la marca",
      upload: "Cargar imagen",
    },
    messages: {
      error: {
        file_is_not_image: "Error en la carga de la imagen",
        default: "Hemos encontrado un error inténtalo de nuevo",
      },
    },
  },
  permission: {
    title: "Equipo",
    btncancel: "Cancelar",
    btnsend: "Enviar invitación",
    subtitle: "Agregar Miembro ",
    email: "Correo",
    list: {
      title: "Permisos",
      subtitle: "Empleado tendra los siguientes permisos:",
      selectall: "Seleccionar todo",
      dashboard: {
        title: "Dashboard",
        title_desc:
          "El usuario podrá acceder a toda la información del módulo ",
        dashStatics: "Estadísticas",
        dashStatics_desc: "Información de la gestión realizada en el mes",
      },
      event_item: {
        title: "Eventos",
        title_desc: "El usuario podrá acceder a toda la información del módulo",
        eventsManage: "Gestion de eventos",
        eventsManage_desc:
          "El usuario podra realizar cualquier tipo de cambios a los eventos",
        eventsAddEvent: "Crear Eventos",
        eventsAddEvent_desc: "El usuario podrá crear eventos",
        eventsEditEvent: "Editar eventos",
        eventsEditEvent_desc: "El usuario podrá editar eventos",
        eventsDeleteEvent: "Eliminar Eventos",
        eventsDeleteEvent_desc: "El usuario podrá eliminar eventos",
        eventsModeration: "Moderación",
        eventsModeration_desc:
          "El usuario podrá moderar los eventos a los que esté asignado",
        eventsShowStatics: "Estadísticas",
        eventsShowStatics_desc:
          "El usuario podrá visualizar las estadísticas post-Live",
      },
      credential: {
        title: "Credenciales",
        title_desc: "El usuario podrá acceder a toda la información del módulo",
        credentialsAdd: "Crear credenciales",
        credentialsAdd_desc:
          "El usuario podrá crear y asignar credenciales a otros usuarios",
        credentialsShow: "Ver credenciales",
        credentialsShow_desc:
          "El usuario podrá ver sus credenciales y las de otros usuarios",
        credentialsDelete: "Eliminar credenciales",
        credentialsDelete_desc:
          "El usuario podrá eliminar sus credenciales y las de otros usuarios",
      },
      team: {
        title: "Equipo",
        title_desc:
          "El usuario podrá acceder a toda la información del módulo ",
        teamAddMember: "Agregar miembros",
        teamAddMember_desc:
          "El usuario podrá invitar personas a hacer parte de su equipo de trabajo ",
        teamEditMember: "Editar miembros",
        teamEditMember_desc:
          "El usuario podrá editar los permisos de otros miembros del equipo",
        teamDeleteMember: "Eliminar miembros",
        teamDeleteMember_desc:
          "El usuario podrá eliminar a otros miembros del equipo",
      },
      products: {
        title: "Productos",
        title_desc: "El usuario podrá acceder a toda la información del módulo",
        productsAddProduct: "Crear productos",
        productsAddProduct_desc: "El usuario podrá crear a productos",
        productsBatchUpload: "Cargues masivos",
        productsBatchUpload_desc: "El usuario podrá realizar cargues masivos",
        productsEditProduct: "Editar productos",
        productsEditProduct_desc: "El usuario podrá editar a productos",
        productsDeleteProduct: "Eliminar productos",
        productsDeleteProduct_desc: "El usuario podrá eliminar a productos",
      },
      firatv: {
        title: "Fira Tv",
        title_desc: "El usuario podrá acceder a toda la información del módulo",
        firaTvRebroadcast: "Retransmitir",
        firaTvRebroadcast_desc:
          "El usuario podrá seleccionar que lives quiere que se visualicen en la landing page",
        firaTvExportBroadcast: "Exportar",
        firaTvExportBroadcast_desc: "El usuario podrá exportar videos",
        firaTvDeleteBroadcast: "Eliminar",
        firaTvDeleteBroadcast_desc: "El usuario podrá eliminar videos",
      },
    },
    inactivate: "inactivar",
    activate: "activar",
    user: "Usuario",
    modal: {
      title: "¡Atención!",
      desc: "¿Seguro(a) que desea {status} a {userName} ?",
      accept: "Aceptar",
      cancel: "Cancelar",
    },
  },
  event_creation: {
    live: "Crear Live",
    generalRoute: "Detalles generales",
    teamRoute: "Equipo",
    productsRoute: "Productos",
    paramsRoute: "Parámetros",
    prevButton: "Regresar",
    nextButton: "Siguiente",
    save: "Guardar",
    credentials: {
      title: "Selecciona tu credencial",
      message:
        "Escoge la opcion de credencial más cercana a tu país, para tener una mejor conexión",
    },
    general_details: {
      title: "Información general",
      message: "Completa la siguiente información relacionada con tu evento",
      forms: {
        event_name: "Nombre del evento",
        datetime: "Fecha y hora",
        desc: "Descripción del evento",
        select_categories: "Seleccionar categorías",
        load_teaser: "Cargar teaser",
        load_image: "Cargar imagen",
        optional: "(Opcional)",
        teaser_info: "Video con avance del evento",
        file_format: "Formato de archivo",
        recommended_size: "Tamaño recomendado",
        image_info: "Imagen de portada para su evento",
        globalModeration: "Moderación global",
        individuallModeration: "Moderación individual",
        infoGlobal:
          "Selecciona 'Moderación Global' si prefieres que la moderación del chat sea gestionada centralizada por la cuenta principal.",
        infoIndividual:
          "Selecciona 'Moderación Individual' si deseas que cada tienda tenga su propio chat y gestióne moderación del chat de forma independiente",
      },
      image_error:
        "La imagen seleccionada tiene un formato o tamaño incorrecto, intente nuevamente.",
    },
    team: {
      title: "Selecciona tu Equipo",
      message: "Escoge los integrantes que estarán activos en el evento",
      placeholder: "Toca para Buscar...",
      selected: "Seleccionados",
      info: "El dueño de cuenta, el(los) administrador(es) y el creador del live siempre estarán seleccionados como moderadores.",
    },
    params: {
      interfaceTitle: "Selecciona la configuración de tu interfaz de video",
      interfaceMessage:
        "Escoge o crea una plantilla según las características que necesites",
      interfaceButton: "Seleccionar",
      interfaceAddMore: "Agregar",
      colorSelectionTitle: "Color principal de la transmisión",
      colorSelectionMessage:
        "Selecciona el color que utilizarás para resaltar las interacciones de tu marca en el chat",
      chat_and_web: "Chat y productos Web",
      productsMockup: "Productos",
      loginParamsTitle: "Habilitación del Login",

      loginParamsMessage:
        "Captura información más detallada de tu audiencia habilitando el login",
      loginEnabled: "Login habilitado",
      addFields: "Agregar Parámetros Adicionales",
      requiredField: "Obligatorio",
      attachmentsTitle: "Documentos adjuntos",
      attachmentsMessage:
        "Carga y selecciona los elementos que vas a utilizar durante la sesión",
      loadingIcon: "Ícono de carga",
      shoppingBag: "Bolsa de compras",
      likes: "Imágenes de likes",
      words_to_moderate: "Palabras a Moderar",
      fieldTypes: {
        username: "Nombre de Usuario",
        text: "Texto",
        email: "Correo",
        password: "Contraseña",
        tel: "Teléfono",
        number: "Número",
      },
      broadcastingResolution: {
        title: "Orientación de la transmisión",
        subtitle:
          "Selecciona el sentido en el que se va a realizar el live, este puede ser horizontal o vertical",
        horizontal: "Transmisión horizontal",
        vertical: "Transmisión vertical",
      },
      terms_and_conditions: "Ingresa la URL de los Términos y Condiciones",
      terms_and_conditions_personal_data:
        "Ingresa la URL del tratamiento de datos personales",
      default: "Por defecto",
      custom: "Personalizado",
      drag: "Arrastra tus documentos",
      add_word_placeholder: "Añadir palabra...",
      attachments: "Adjuntos",
      load: "Cargar archivo",
      colorPreview: "Previsualización de color",
      buyButtonTitle: "Configuración botón de producto",
      buyButtonMessage:
        "Personaliza el texto del botón de venta de tus productos",
      buyButtonDefault: "Por defecto",
      buyButtonCustom: "Personalizado",
      buyButtonText: "Texto botón",
      buyButtonSave: "Guardar",
      buyButtonPlaceholder: "Ej: Comprar",
      buyButtonPrev: "Previsualización",
      buyButtonProductName: "Nombre del producto",
      buyButtonView: "Comprar",
      noSavedAlert:
        "¡Ups! Para continuar haz clic en guardar para confirmar la información",
    },
    incorrect_file_loaded: "Revisa que tu archivo sea ",
    fail: "¡No se pudo crear el evento!",
    fail_message:
      "En el momento estamos teniendo fallas, por favor intenta más tarde.",
    fail_btn: "Entendido",
  },
  dropzone: {
    title: "Arastra tus documentos",
  },
  toggle: {
    yes: "SI",
    no: "NO",
  },
  widgets: {
    audienceResume: {
      title: "Resumen de la situación",
      audiencia: "Audiencia",
    },
    interactions: {
      title: "Interacciones",
      subtitle: "En este espacio se podran visualizar todos los",
      subtitle_2: "Cantidad total según tipo de interacción",
      likes: "Me Gusta",
      shares: "Compartidos",
      messages: "Mensajes",
      clicks: "Clicks",
      total: "Total",
    },
    viewsByDevices: {
      title: "Visualización por dispositivo",
      desktop: "Desktop",
      mobile: "Movil",
      other: "Otro",
    },
    documentation: {
      title1: "Mejore su ",
      title2: "Campaña",
      title3: " para un mejor alcance",
      button: "Proximamente",
    },
    card: {
      availableTime: "Tiempo disponible", //available time
      avgReproduction: "Promedio de reproducción",
    },
    highlights: {
      title: "Lives destacados",
      subtitle: "En este espacio se podrá visualizar 3 lives destacados",
      empty: "No tienes más eventos",
      select: {
        likes: "Likes",
        shares: "Shares",
        clicks: "Clicks",
        messages: "Mensajes",
        audience: "Audiencia",
        filter: "Filtro",
      },
    },
  },
  calendar: {
    from: "Desde",
    to: "Hasta",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    sunday: "D",
    monday: "L",
    tuesday: "M",
    wednesday: "M",
    thursday: "J",
    friday: "V",
    saturday: "S",
  },
  tips: {
    main_store:
      "<strong>Nota:</strong> La tienda marcada con una ⭐ es la que se utilizara para eventos simultáneos y masivos.",
  },
  inputs: {
    searchPlaceholder: "Toca para Buscar....",
    varKeyPlaceholder: "Nombre de variable",
    varValuePlaceholder: "Valor por defecto de la variable",
  },
  forms: {
    name_label: "Nombre",
    lastname_label: "Apellido",
    user_label: "Usuario",
    email_label: "Correo Electronico",
    phonenumber_label: "Numero Telefonico",
    whatsapp_label: "WhatsApp para recibir tus pedidos",
    password: "Contraseña",
  },
  table: {
    header: {
      name: "Nombre",
      email: "Correo",
      invite_date: "Fecha de invitación",
      store: "Tienda",
      role: "Rol",
      status: "Estado",
    },
  },
  buttons: {
    save: "Guardar",
    cancel: "Cancelar",
    next: "Siguiente",
    back: "Atras",
    create_account: "Crear Cuenta",
    copy_url: "Copiar URL",
    try_again: "Volver a intentar",
    preview: "Vista previa",
    setup: "Configurar",
    select_all: "Seleccionar todo",
    add_vars: "Agregar Paramentos Adicionales",
    resend: "Reenviar",
    annul: "Anular",
    continue: "Continuar",
  },
  alerts: {
    success: "Cambio guardado",
    url_copy: "¡URL copiada!",
    landing_error: "Tienda ya posee landing:",
    saving: "Guardando",
    vars_error: "Ambos, la clave y el valor, deben estar llenos.",
    vars_url_error:
      "Ambos, la clave y el valor, deben ser parámetros válidos de URL.",
    invite_success: "¡Invitacion enviada!",
    invite_cancelled: "invitación anulada",
    error: "Encontramos un error, intente de nuevo.",
    image_will_delete: "Esta imagen se eliminara al guardar",
  },
  invitation: {
    status: {
      CANCELLED: "Anulada",
      PENDING: "Pendiente",
      ACCEPTED: "Aceptada",
      CLOSED: "Rechazada",
    },
  },
  user: {
    role: {
      OPERATOR: "Operador",
      MODERATOR: "Moderador",
      MANAGER: "Administrador",
    },
  },
  wizard: {
    steps: {
      COLOR: "Color",
      BUTTON: "Botón de compra",
      ORIENTATION: "Orientación",
      LOGIN: "Login",
    },
  },
};
