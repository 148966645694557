import React from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import { useTranslation } from "react-i18next";

import stylesheet from "./SignUpExpress.module.scss";
import Input, { InputStyles } from "../../components/Input/Input";
import Button, { ButtonStyle } from "../../components//Button/Button";
import { CountryCode, getPhoneCodes, getRegion } from "../../services/utils";
import { ArrowDownFill, FiraLogo } from "../../components/FiraIcons";
import { checkSignUpExpressStatus, signUpExpress } from "../../services/auth";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";

const MAX_RETRY = 2;

interface Form {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: string;
}
enum WrapperState {
  INIT,
  LOADING,
  SUCCESS,
  FAILED,
  SIGN_UP_DISABLED,
}

const SignUpExpress: React.FC = () => {
  const { t } = useTranslation();
  const [form, setForm] = React.useState<Form>({
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
  });
  const [phoneCode, setPhoneCode] = React.useState<CountryCode>();
  const [state, setState] = React.useState<WrapperState>(WrapperState.LOADING);
  const [result, setResult] = React.useState("");
  const showAlert = usePopupAlert();
  const { id } = useParams();
  const [codes, setCodes] = React.useState<CountryCode[]>([]);
  const [isPasswordRequired, setIsPasswordRequired] = React.useState(false);
  const [customDataActived, setCustomDataActived] = React.useState(false);
  const [customData, setCustomData] = React.useState<{
    [key: string]: string;
  } | null>();

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value.trim().replaceAll(" ", "") });
  };

  const customInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCustomData((prev) => ({ ...prev, [name]: value }));
  };

  const phoneSelectHandler = (code: CountryCode) => {
    setPhoneCode(code);
  };

  const submitHandler = async () => {
    try {
      setState(WrapperState.LOADING);
      const parseData = {
        businessClientId: id,
        firstName: form.firstName,
        lastName: form.lastName,
        userEmail: form.email,
        username: form.username,
        password: form.password,
        landingName: "",
        productLabel: null,
        phoneNumber: {
          country: phoneCode?.countryCode,
          operator: phoneCode?.dialCode,
          number: form.phoneNumber,
          type: "MOBILE",
        },
        uniqueExternalCode: "",
        role: null,
        customFields: customData,
      };
      const response = await signUpExpress(parseData);
      setState(WrapperState.SUCCESS);
      setResult(response.message);
    } catch (error) {
      setState(WrapperState.FAILED);
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const handleURLCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_BASE_URL_LANDING}${result}`
      );
      showAlert(AlertType.success, t("alerts.url_copy"));
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const renderSuccess = () => {
    return (
      <div className={stylesheet.SuccessWrapper}>
        <span>{`${t("auth.express.success_title")}, ${form.firstName}`}</span>
        <span>{t("auth.express.fira.success_description")}</span>
        <a
          href={`${process.env.REACT_APP_BASE_URL_LANDING}${result}`}
          target="_blank"
          className={stylesheet.URL}
        >
          {`${process.env.REACT_APP_BASE_URL_LANDING}`}
          <span>{result}</span>
        </a>
        <Button
          buttonStyle={ButtonStyle.main}
          height="48px"
          clickHandler={handleURLCopy}
        >
          {t("buttons.copy_url")}
        </Button>
      </div>
    );
  };

  const renderFailed = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <span>{t("auth.express.fira.failed_description")}</span>
        <Button background="#F16063" height="40px" clickHandler={submitHandler}>
          <div className={stylesheet.InnerButton}>
            <Icon icon="uiw:reload" />
            <span>{t("buttons.try_again")}</span>
          </div>
        </Button>
      </div>
    );
  };

  const renderSignUpDisabled = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <div className={stylesheet.SignUpDisabled}>
          <span className={stylesheet.title}>
            {t("auth.express.disabled.title")}
          </span>
          <span className={stylesheet.message}>
            {t("auth.express.disabled.message")}
          </span>
          <span className={stylesheet.message}>
            {t("auth.express.disabled.closing")}
          </span>
        </div>
      </div>
    );
  };

  const renderWrapper = () => {
    switch (state) {
      case WrapperState.INIT:
        return (
          <SignUpForm
            form={form}
            phoneCode={phoneCode}
            codes={codes}
            passwordRequired={isPasswordRequired}
            customFieldsRequired={customDataActived}
            customFields={customData ? customData : {}}
            onPhoneCodeChange={phoneSelectHandler}
            onInputChange={inputChangeHandler}
            onCustomInputChange={customInputChangeHandler}
            onSubmit={submitHandler}
          />
        );
      case WrapperState.LOADING:
        return <FiraLogo />;
      case WrapperState.SUCCESS:
        return renderSuccess();
      case WrapperState.FAILED:
        return renderFailed();
      case WrapperState.SIGN_UP_DISABLED:
        return renderSignUpDisabled();
    }
  };

  React.useEffect(() => {
    const initForm = async () => {
      try {
        const response = await checkSignUpExpressStatus(id ?? "");
        setIsPasswordRequired(response.fieldActive);
        setCustomDataActived(response.customFieldActive || false);
        setCustomData(response.customFields);
        if (response.status === "ENABLED") {
          if (id) {
            setState(WrapperState.INIT);
          }
        } else {
          setState(WrapperState.SIGN_UP_DISABLED);
        }
      } catch (error) {
        showAlert(AlertType.error, JSON.stringify(error));
        setState(WrapperState.SIGN_UP_DISABLED);
      }
    };

    initForm();

    const getCodes = async () => {
      try {
        const response = await getPhoneCodes();
        setCodes(response);

        if (id) {
          const res = await getRegion(id);
          console.log(res);
        }

        // const code = response.find((c) =>
        //   c.countryCode.toLowerCase().includes(region)
        // );
        // setPhoneCode(code ?? response[0]);
      } catch (error) {
        // TODO: show error
        console.log(error);
      }
    };

    getCodes();
  }, []);

  return (
    <div className={stylesheet.Wrapper}>
      <Header />
      <div className={stylesheet.InnerWrapper}>{renderWrapper()}</div>
    </div>
  );
};

interface FormProps {
  form: Form;
  phoneCode?: CountryCode;
  codes: CountryCode[];
  passwordRequired: boolean;
  customFieldsRequired: boolean;
  customFields: { [key: string]: string };
  onPhoneCodeChange(code: CountryCode): void;
  onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onCustomInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit(): void;
}
const SignUpForm: React.FC<FormProps> = ({
  form,
  phoneCode,
  codes,
  passwordRequired,
  customFields,
  customFieldsRequired,
  onPhoneCodeChange,
  onInputChange,
  onCustomInputChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const pattern = /^\d{1,}$/;
  const handlePhoneCodeSelect = (code: CountryCode) => {
    onPhoneCodeChange(code);
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form style={FormWrapper} onSubmit={submitHandler}>
      <span style={Title}>{t("auth.express.title")}</span>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="firstName">
            {t("forms.name_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.normal}
          inputName="firstName"
          value={form.firstName}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="lastName">
            {t("forms.lastname_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.normal}
          inputName="lastName"
          value={form.lastName}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="username">
            {t("forms.user_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.normal}
          inputName="username"
          value={form.username}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="email">
            {t("forms.email_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.normal}
          inputName="email"
          value={form.email}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      {passwordRequired && (
        <div style={InputWrapper}>
          <div className={stylesheet.LabelWrapper}>
            <label className={stylesheet.Label} htmlFor="password">
              {t("forms.password")}
            </label>
          </div>

          <Input
            inputStyle={InputStyles.normal}
            inputName="password"
            type={"password"}
            value={form.password}
            onChange={onInputChange}
            width="100%"
            height="40px"
          />
        </div>
      )}
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="phoneNumber">
            {t("forms.whatsapp_label")}
          </label>
        </div>

        <div style={InnerInputWrapper}>
          <PhoneCodeSelect
            phoneCode={phoneCode}
            codes={codes}
            onPhoneCodeSelect={handlePhoneCodeSelect}
          />
          <Input
            inputStyle={InputStyles.normal}
            inputName="phoneNumber"
            value={form.phoneNumber}
            onChange={onInputChange}
            width="100%"
            height="40px"
            type="tel"
          />
        </div>
      </div>
      {customFieldsRequired &&
        Object.keys(customFields).map((key) => (
          <div style={InputWrapper}>
            <div className={stylesheet.LabelWrapper}>
              <label className={stylesheet.Label} htmlFor={key}>
                {key}
              </label>
            </div>

            <Input
              inputStyle={InputStyles.normal}
              inputName={key}
              value={customFields[key]}
              onChange={onCustomInputChange}
              width="100%"
              height="40px"
            />
          </div>
        ))}
      <div style={InputWrapper}>
        <Button
          type="submit"
          buttonStyle={ButtonStyle.main}
          isDisabled={!(pattern.test(form.phoneNumber) && form.username !== "")}
          height="48px"
          width="100%"
        >
          <span style={{ color: "#fff" }}>{t("buttons.create_account")}</span>
        </Button>
      </div>
    </form>
  );
};

const Title: React.CSSProperties = {
  fontSize: 24,
  fontWeight: 500,
};

const FormWrapper: React.CSSProperties = {
  width: "350px",
  display: "flex",
  overflowY: "auto",
  flexDirection: "column",
  gap: 24,
};

const InputWrapper: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 8,
};

const InnerInputWrapper: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "130px 1fr",
  gap: 8,
};

interface PhoneCodeSelect {
  phoneCode?: CountryCode;
  codes: CountryCode[];
  onPhoneCodeSelect(code: CountryCode): void;
  showName?: boolean;
}
const PhoneCodeSelect: React.FC<PhoneCodeSelect> = ({
  phoneCode,
  codes,
  onPhoneCodeSelect,
  showName = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onCodeSelectHandler = (code: CountryCode) => {
    onPhoneCodeSelect(code);
    setIsOpen(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={stylesheet.CodeWrapper}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={stylesheet.SelectButton}
        type="button"
      >
        {phoneCode && (
          <>
            <img
              style={{ width: 32 }}
              src={phoneCode.flagUrl}
              alt={phoneCode.name}
            />
            <span>{showName ? phoneCode.name : phoneCode.dialCode}</span>
            <ArrowDownFill className={stylesheet.Icon} />
          </>
        )}
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={stylesheet.SelectList}>
          {codes.map((code, index) => (
            <div
              className={stylesheet.ListItem}
              onClick={() => onCodeSelectHandler(code)}
              key={index}
            >
              <img src={code.flagUrl} alt={code.name} />
              <span>{code.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Header = () => {
  return (
    <div className={stylesheet.Header}>
      <FiraLogo />
      <div className={stylesheet.Border}></div>
    </div>
  );
};

export default SignUpExpress;
