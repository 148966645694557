import { createContext, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSuccessMessageActionCreator,
  editStoreProfileActionCreator,
} from "../../../store/Brand/BrandActions";
import { RootState } from "../../../store/Store";
import { setSettingsModalStateActionCreator } from "../../../store/Utils/UtilsActions";
import {
  Phone,
  SocialMediaType,
  StoreContactType,
  StoreGeneralInformationType,
  StoreLocationType,
  FiraStore,
  StoreType,
  RegionalConfigurationType,
  BrandContactType,
} from "../../../types/Stores";
import Button, { ButtonStyle } from "../../Button/Button";
import Skeleton from "../../Skeleton/Skeleton";
import ContactInformationForm from "./ContactInformationForm/ContactInformationForm";
import GeneralInformationForm from "./GeneralInformationForm/GeneralInformationForm";
import styles from "./StoreProfile.module.scss";
import React from "react";
import BrandIdentityForm from "./BrandIdentityForm/BrandIdentityForm";
import { AlertType, usePopupAlert } from "../../../providers/AlertProvider";
import MassificationForm from "./MassificationForm/MassificationForm";

interface Tab {
  name: string;
  type: TabType;
  component: ReactElement;
  active: boolean;
  disabled: boolean;
}

enum TabType {
  general = "GENERAL",
  brand = "BRAND",
  contact = "CONTACT",
  massification = "MASSIFICATION",
}

export enum FileType {
  favicon = "FAVICON",
  logo = "LOGO",
}

export const GeneralInfoInitialState: StoreGeneralInformationType = {
  name: "",
  legalName: "",
  description: "",
  location: {
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    province: "",
  },
  regionalConfiguration: {
    currency: "",
    language: "",
    timeZone: "",
    weightUnit: "",
  },
  storeType: "",
  isAutoExpose: false,
  additionalFields: {},
  customFields: {},
};

export const ContactInformationInitialState: StoreContactType = {
  emails: [],
  phones: [],
  socialMedia: [],
  webpages: [],
};

export const BrandContactInitialState: BrandContactType = {
  logo: "",
  favicon: "",
  banner: "",
  name: "",
  fontName: "",
  mainColor: "",
  secondaryColor: "",
};

export const StoreProfileContext = createContext<any>(GeneralInfoInitialState);

const StoreProfile = () => {
  return (
    <>
      <div className={styles.SettingsStoreWrapper}>
        <div className={styles.ItemContainer}>
          <StoreProfileConfig />
        </div>
      </div>
    </>
  );
};

const StoreProfileConfig = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brand } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [somethingChanged, setSomethingChanged] = useState<boolean>(false);
  const [fixed, setFixed] = useState<StoreGeneralInformationType>(
    GeneralInfoInitialState
  );
  const [form, setForm] = useState<StoreGeneralInformationType>(
    GeneralInfoInitialState
  );
  const [fixedContact, setFixedContact] = useState<StoreContactType>(
    ContactInformationInitialState
  );
  const [contactForm, setContactForm] = useState<StoreContactType>(
    ContactInformationInitialState
  );
  const [brandForm, setBrandForm] = useState<BrandContactType>(
    BrandContactInitialState
  );
  const [fixedBrand, setFixedBrand] = useState<BrandContactType>(
    BrandContactInitialState
  );
  const [logo, setLogo] = useState<File | null>(null);
  const [favicon, setFavicon] = useState<File | null>(null);
  const showAlert = usePopupAlert();
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false);
  const [invalidGeneralForm, setInvalidGeneralForm] = useState<boolean>(false);
  const [invalidBrandForm, setInvalidBrandForm] = useState<boolean>(false);
  const [invalidContactForm, setInvalidContactForm] = useState<boolean>(false);
  const [tabs, setTabs] = useState<any[]>([]);

  const TABS_FIXED = [
    {
      name: t("components.store_settings.general_info.title"),
      type: "GENERAL",
      component: <GeneralInformationForm />,
      active: true,
      disabled: false,
    },
    {
      name: t("components.store_settings.brand_identity.title"),
      type: "BRAND",
      component: <BrandIdentityForm />,
      active: false,
      disabled: true,
    },
    {
      name: t("components.store_settings.contact_info.title"),
      type: "CONTACT",
      component: <ContactInformationForm />,
      active: false,
      disabled: true,
    },
    {
      name: t("components.store_settings.massification.title"),
      type: "MASSIFICATION",
      component: <MassificationForm />,
      active: false,
      disabled: true,
    },
  ];

  const [currentTab, setCurrentTab] = useState<{
    name: string;
    component: ReactElement;
    type: string;
    active: boolean;
    disabled: boolean;
  }>({
    name: t("components.store_settings.general_info.title"),
    type: "GENERAL",
    component: <GeneralInformationForm />,
    active: true,
    disabled: false,
  });

  const populateFormData = () => {
    const current = brand.currentStore;
    if (current) {
      let body: StoreGeneralInformationType = {
        name: current.name && current.name !== null ? current.name : "",
        legalName:
          current.legalName && current.legalName !== null
            ? current.legalName
            : "",
        description:
          current.brand !== null && current.brand.name !== null
            ? current.brand.name
            : "",
        location: handleLocationInit(current.location),
        regionalConfiguration: handleRegionalConfiguration(
          current.regionalConfiguration
        ),
        storeType: current.storeType,
        isAutoExpose: current.isAutoExpose || false,
        additionalFields: current.additionalFields ?? {},
        customFields: current.customFields ?? {},
      };
      setForm(body);
      setFixed(body);

      /** Contact */
      let contact = {
        emails: current.contact ? handleEmailsInit(current.contact.emails) : [],
        phones: current.contact ? handlePhonesInit(current.contact.phones) : [],
        socialMedia: current.contact
          ? handleSocialMedia(current.contact.socialMedia)
          : [],
        webpages: current.contact
          ? handleWebPages(current.contact.webpages)
          : [],
      };

      setFixedContact(contact);
      setContactForm(contact);

      /** Brand */
      let brand = {
        logo:
          current.brand && current.brand.logo !== null
            ? current.brand.logo
            : "",
        favicon:
          current.brand && current.brand.favicon !== null
            ? current.brand.favicon
            : "",
        banner:
          current.brand && current.brand.banner !== null
            ? current.brand.banner
            : "",
        name:
          current.brand && current.brand.name !== null
            ? current.brand.name
            : "",
        fontName:
          current.brand && current.brand.fontName !== null
            ? current.brand.fontName
            : "",
        mainColor:
          current.brand && current.brand.mainColor !== null
            ? current.brand.mainColor
            : "",
        secondaryColor:
          current.brand && current.brand.secondaryColor !== null
            ? current.brand.secondaryColor
            : "",
      };

      setBrandForm(brand);
      setFixedBrand(brand);
    }
  };

  /** Validaciones para campos Null */
  const handleEmailsInit = (emails: string[] | undefined | null) => {
    if (emails !== null && emails) {
      return emails;
    } else {
      return [];
    }
  };

  const handlePhonesInit = (phones: Phone[] | undefined | null) => {
    if (phones !== null && phones) {
      return phones;
    } else {
      return [];
    }
  };

  const handleSocialMedia = (
    socialMedia: SocialMediaType[] | undefined | null
  ) => {
    if (socialMedia !== null && socialMedia) {
      return socialMedia;
    } else {
      return [];
    }
  };

  const handleWebPages = (webpages: string[] | undefined | null) => {
    if (webpages !== null && webpages) {
      return webpages;
    } else {
      return [];
    }
  };

  const handleLocationInit = (location: StoreLocationType) => {
    if (location === null) {
      return {
        address: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
        province: "",
      };
    } else {
      return {
        address:
          location.address !== null && location.address ? location.address : "",
        city: location.city !== null && location.city ? location.city : "",
        country:
          location.country !== null && location.country ? location.country : "",
        state: location.state !== null && location.state ? location.state : "",
        province:
          location.province !== null && location.province
            ? location.province
            : "",
        zipCode:
          location.zipCode !== null && location.zipCode
            ? String(location.zipCode)
            : "",
      };
    }
  };

  const handleRegionalConfiguration = (
    regionalConfiguration: RegionalConfigurationType
  ) => {
    if (regionalConfiguration === null) {
      return {
        currency: "",
        language: "",
        timeZone: "",
        weightUnit: "",
        unitSystem: "",
      };
    } else {
      return {
        currency:
          regionalConfiguration.currency !== null &&
          regionalConfiguration.currency
            ? regionalConfiguration.currency
            : "",
        language:
          regionalConfiguration.language !== null &&
          regionalConfiguration.language
            ? regionalConfiguration.language
            : "",
        timeZone:
          regionalConfiguration.timeZone !== null &&
          regionalConfiguration.timeZone
            ? regionalConfiguration.timeZone
            : "",
        weightUnit:
          regionalConfiguration.weightUnit !== null &&
          regionalConfiguration.weightUnit
            ? regionalConfiguration.weightUnit
            : "",
        unitSystem:
          regionalConfiguration.unitSystem !== null &&
          regionalConfiguration.unitSystem
            ? regionalConfiguration.unitSystem
            : "",
      };
    }
  };
  /** Validaciones para campos Null */

  const handleContactChange = (info: StoreContactType) => {
    setContactForm(info);
  };

  const handleChange = (info: StoreGeneralInformationType) => {
    setForm(info);
  };

  const handleFileChange = (file: File, type: FileType) => {
    if (type === FileType.favicon) {
      setFavicon(file);
    } else if (type === FileType.logo) {
      setLogo(file);
    }
    return;
  };

  const handleBrandChange = (info: BrandContactType) => {
    setBrandForm(info);
  };

  const handleTabClick = (clickedTab: Tab) => {
    const updatedTabs = tabs.map((tab) => {
      return {
        ...tab,
        active: tab === clickedTab,
      };
    });
    setTabs(updatedTabs);
  };

  const handleNextClick = () => {
    if (currentTab.type !== TabType.massification) {
      const currentIndex = tabs.findIndex((tab) => tab.active);

      if (currentIndex < tabs.length - 1) {
        const updatedTabs = [...tabs];
        updatedTabs[currentIndex].disabled = false;
        updatedTabs[currentIndex].active = false;

        updatedTabs[currentIndex + 1].active = true; // Activate the next tab
        updatedTabs[currentIndex + 1].disabled = false;

        setCurrentTab(tabs[currentIndex + 1]);
      }
    } else {
      handleSubmit();
    }
  };

  const handlePrevClick = () => {
    if (currentTab.type !== TabType.general) {
      const currentIndex = tabs.findIndex((tab) => tab.active);

      if (currentIndex > 0) {
        const updatedTabs = [...tabs];
        updatedTabs[currentIndex].active = false;

        updatedTabs[currentIndex - 1].active = true; // Activate the prev tab
        setCurrentTab(tabs[currentIndex - 1]);
      }
    } else {
      Discard();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (form && brand.currentStore && contactForm) {
      let body: FiraStore = {
        productConfig: null,
        id: brand.currentStore.id,
        name: form.name,
        legalName: form.legalName,
        businessClientId: brand.currentStore.businessClientId,
        storeType: form.storeType as StoreType,
        location: {
          address: form.location.address || "",
          city: form.location.city || "",
          province: form.location.province || "",
          country: form.location.country || "",
          zipCode: form.location.zipCode || "",
          region: form.location.region || "",
          state: form.location.state || "",
        },
        regionalConfiguration: {
          language: form.regionalConfiguration.language || "",
          currency: form.regionalConfiguration.currency || "",
          timeZone: form.regionalConfiguration.timeZone || "",
          weightUnit: form.regionalConfiguration.weightUnit || "KILOGRAMS",
          unitSystem: form.regionalConfiguration.unitSystem || "METRIC",
        },
        brand: {
          name: brand.currentStore.brand !== null ? form.description : "",
          favicon: brand.currentStore.brand !== null ? brandForm.favicon : "",
          logo: brand.currentStore.brand !== null ? brandForm.logo : "",
          banner: brand.currentStore.brand !== null ? brandForm.banner : "",
          fontName: brand.currentStore.brand !== null ? brandForm.fontName : "",
          mainColor:
            brand.currentStore.brand !== null ? brandForm.mainColor : "",
          secondaryColor:
            brand.currentStore.brand !== null ? brandForm.secondaryColor : "",
        },
        state: brand.currentStore.state,
        contact: {
          emails: contactForm.emails || [],
          socialMedia: contactForm.socialMedia || [],
          phones: contactForm.phones || [],
          webpages: contactForm.webpages || [],
        },
        workflowList: brand.currentStore.workflowList,
        isAutoExpose:
          brand.currentStore.isAutoExpose !== null ? form.isAutoExpose : false,
        additionalFields: form.additionalFields,
        customFields: brand.currentStore.customFields,
      };

      dispatch(
        editStoreProfileActionCreator(
          brand.currentStore.id,
          body,
          logo !== null ? logo : undefined,
          favicon !== null ? favicon : undefined
        )
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  };

  const Discard = () => {
    setIsLoading(true);

    //TO DO: discard this
    if (somethingChanged) {
      setForm(fixed);
      setBrandForm(fixedBrand);
      setContactForm(fixedContact);
      setTabs(TABS_FIXED);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      dispatch(setSettingsModalStateActionCreator(false));
    }
  };

  const initialValidator = (tab: TabType) => {
    if (tab === TabType.brand && brandForm) {
      return brandForm.fontName !== "" && brandForm.logo !== "";
    } else if (tab === TabType.contact && contactForm) {
      return contactForm.phones.length > 0 && contactForm.webpages.length > 0;
    } else return false;
  };

  const ButtonValidator = () => {
    if (isLoading) {
      return true;
    }

    switch (currentTab.type) {
      case TabType.general:
        return invalidGeneralForm;
      case TabType.brand:
        return invalidBrandForm;
      case TabType.contact:
        return invalidContactForm;
      default:
        return false;
    }
  };

  useEffect(() => {
    form !== fixed ||
    contactForm !== fixedContact ||
    brandForm !== fixedBrand ||
    logo !== null ||
    favicon !== null
      ? setSomethingChanged(true)
      : setSomethingChanged(false);
  }, [form, contactForm, brandForm]);

  useEffect(() => {
    populateFormData();
  }, [brand]);

  useEffect(() => {
    brand.error &&
      showAlert(AlertType.error, t("components.store_settings.error"));
    if (brand.editSuccess) {
      showAlert(AlertType.success, t("components.store_settings.success"));
      setTabs(TABS_FIXED);
    }
  }, [brand.error, brand.editSuccess]);

  useEffect(() => {
    if (tabs !== []) {
      setCurrentTab(tabs.find((tab) => tab.active));
      setIsLoading(false);
    }
    setInvalidBrandForm(!initialValidator(TabType.brand));
    setInvalidContactForm(!initialValidator(TabType.contact));
  }, [tabs]);

  useEffect(() => {
    if (!invalidBrandForm && tabs.length > 1) {
      const updatedTabs = [...tabs];
      updatedTabs[1].disabled = false;
      setTabs(updatedTabs);
    }
  }, [invalidBrandForm]);

  useEffect(() => {
    if (!invalidContactForm && tabs.length > 1) {
      const updatedTabs = [...tabs];
      updatedTabs[2].disabled = false;
      setTabs(updatedTabs);
    }
  }, [invalidContactForm]);

  useEffect(() => {
    dispatch(cleanSuccessMessageActionCreator());
    setTabs(TABS_FIXED);
    return () => {
      dispatch(cleanSuccessMessageActionCreator());
    };
  }, []);

  return (
    <>
      <div className={styles.StoreProfileConfigWrapper}>
        {currentTab && (
          <>
            <SettingsTabs tabs={tabs} clickHandler={handleTabClick} />
            <StoreProfileContext.Provider
              value={{
                form,
                fixed,
                handleChange,
                fixedContact,
                handleContactChange,
                brandForm,
                fixedBrand,
                handleBrandChange,
                handleFileChange,
                invalidUrl,
                setInvalidUrl,
                setInvalidGeneralForm,
                setInvalidBrandForm,
                setInvalidContactForm,
                contactForm,
              }}
            >
              {isLoading ? <ProfileSkeleton /> : currentTab.component}
            </StoreProfileContext.Provider>
            <div className={styles.StoreProfileConfigWrapper__Buttons}>
              <Button
                buttonStyle={ButtonStyle.border}
                width={"110px"}
                height={"32px"}
                clickHandler={handlePrevClick}
              >
                {currentTab.name !== "GENERAL" ? (
                  <span>{t("components.store_settings.prev_btn")}</span>
                ) : (
                  <span>{t("components.store_settings.cancel_btn")}</span>
                )}
              </Button>
              <Button
                width={"110px"}
                height={"32px"}
                isDisabled={ButtonValidator()}
                clickHandler={handleNextClick}
              >
                {currentTab.type !== "MASSIFICATION" ? (
                  <span>{t("components.store_settings.next_btn")}</span>
                ) : (
                  <span>{t("components.store_settings.save_btn")}</span>
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface SettingsTabProps {
  tabs: {
    name: string;
    component: ReactElement;
    type: string;
    active: boolean;
    disabled: boolean;
  }[];
  clickHandler(tab: {
    name: string;
    component: ReactElement;
    type: string;
    active: boolean;
    disabled: boolean;
  }): void;
}

const SettingsTabs = ({ tabs, clickHandler }: SettingsTabProps) => {
  return (
    <>
      <ul className={styles.StoreProfileConfigWrapper__TabsNavbar}>
        {tabs &&
          tabs.map((tab: any, i: number) => (
            <React.Fragment key={i}>
              <li
                onClick={() => clickHandler(tab)}
                className={`${tab.disabled ? styles.disabled : ""}`}
              >
                <span className={`${tab.active ? styles.active : ""}`}>
                  {tab.name}
                </span>
              </li>
              <div className={styles.divider}></div>
            </React.Fragment>
          ))}
      </ul>
    </>
  );
};

const ProfileSkeleton = () => {
  return (
    <>
      <div className={styles.ProfileSkeleton}>
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <Skeleton width="100%" height="40px" />
        <div style={{ gridColumn: "span 2" }}>
          <Skeleton width="100%" height="140px" />
        </div>
      </div>
    </>
  );
};

export default StoreProfile;
