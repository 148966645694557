import { RolesAndPermissionsType } from "../components/RolesAndPermissions/RolesAndPermissions";
import {
  FiraUserResponse,
  FiraInvite,
  OwnerDataResponse,
  SimplePermissionItem,
  FiraOwnerTeamMemberResponse,
} from "../types/Response";
import {
  FiraPermissions,
  FiraUserForm,
  InvitationForm,
  InvitationType,
  PermissionsByGroupType,
  RoleType,
  TeamMember,
} from "../utils/types";
import { apiManagementPrivate, apiBusiness, apiBusinessPrivate } from "./api";

// DEPRECATED
export const getUserService = (userId: string) => {
  return apiManagementPrivate.get(`/api/v1/fira-user/${userId}`);
};
///

export const getFiraUserService = (id: string) => {
  return apiBusinessPrivate.get<FiraUserResponse>(
    `/v1/user-service/find-by-id/${id}`
  );
};

export const updateUserService = (userId: string, body: FiraUserForm) => {
  return apiManagementPrivate.put(
    `/api/v1/fira-user/update-profile/${userId}`,
    body
  );
};
//  DEPRECATED
export const getTeamService = (storeId: string) => {
  return apiManagementPrivate.get(
    `/api/v1/fira-user/permissions/store/${storeId}`
  );
};
//

export const getStoreTeamService = (
  storeId: string,
  offSet: number,
  size: number
) => {
  return apiBusinessPrivate.get<TeamMember>(
    `/v1/user-service/all-users-store/${storeId}/${offSet}/${size}`
  );
};

export const postTeamService = (body: FiraPermissions) => {
  return apiManagementPrivate.post(
    "/api/v1/fira-user/create-permissions",
    body
  );
};

//DEPRECATED
export const postSendInvitationService = (body: InvitationForm) => {
  return apiManagementPrivate.post("/api/v1/fira-user/send-invitation", body);
};
//
export const updateUserStatusService = (userId: string, storeId: string) => {
  return apiBusinessPrivate.put(
    `/v1/user-service/change-user-status/${userId}/${storeId}`
  );
};

export const getInvitationListService = (email: string) => {
  return apiManagementPrivate.get(`/api/v1/fira-user/invitation-list/${email}`);
};

export const postAcceptInvitationService = (invitationId: string) => {
  return apiBusinessPrivate.post(
    `/v1/user-invitation-service/accept-invitation/${invitationId}`
  );
};

export const postRejectInvitationService = (invitationId: string) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-user/reject-invitation/${invitationId}`
  );
};

export const getModeratorsByStoreIdService = (storeId: string) => {
  return apiBusinessPrivate.get<FiraOwnerTeamMemberResponse[]>(
    `/v1/user-service/get-users-moderators/${storeId}`
  );
};

//OWNER SERVICES
export const getOwnerSubscriptions = (businessClientId: string) => {
  return apiBusinessPrivate.get(
    `/v1/internal/fira-business/business-client/find-by-id/${businessClientId}`
  );
};

export const updateAccountOwnerProfile = (body: FiraUserForm) => {
  return apiBusinessPrivate.put(`/v1/user-service/update-profile`, body);
};

export const getOwnerData = (id: string) => {
  return apiBusinessPrivate.get<OwnerDataResponse>(
    `/v1/internal/fira-business/business-client/find-by-id/${id}`
  );
};

export const sendInvitationService = (body: InvitationType) => {
  return apiBusinessPrivate.post(
    `/v1/user-invitation-service/send-invitation`,
    body
  );
};

export const getPermissionsByGroupService = (group: PermissionsByGroupType) => {
  return apiBusinessPrivate.get(
    `/v1/user-service/get-permissions-by-group/${group}`
  );
};

export const findByIdAndStoresService = (userId: string, stores: string[]) => {
  let body = {
    userId: userId,
    storeList: stores,
  };
  return apiBusinessPrivate.post(
    `/v1/user-service/find-by-id-and-stores`,
    body
  );
};

export const changeUserRoleService = (
  userId: string,
  role: string,
  storeId: string
) => {
  return apiBusinessPrivate.put(
    `/v1/user-service/change-user-role/${userId}/${role}/${storeId}`
  );
};

export const deteleUserService = (userId: string) => {
  return apiBusinessPrivate.put(`/v1/user-service/delete-user/${userId}`);
};

export const deleteUserFromStoreService = (userId: string, storeId: string) => {
  return new Promise((resolve, reject) => {
    apiBusinessPrivate
      .put(`/v1/user-service/delete-user-store/${userId}/${storeId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatePermissionsService = (body: RolesAndPermissionsType) => {
  return apiBusinessPrivate.put(`/v1/user-service/update-permissions`, body);
};

export const getPermissions = (
  permissionCategory: string
): Promise<string[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<string[]>(
        "/v1/authentication-service/get-permissions",
        { params: { permissions: permissionCategory } }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getOperatorPermissions = (): Promise<SimplePermissionItem[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<SimplePermissionItem[]>(
        "/v1/authentication-service/get-operator-permissions"
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllInvitations = (
  businessClientId: string
): Promise<FiraInvite[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<FiraInvite[]>(
        `/v1/user-invitation-service/by-business-client-id/${businessClientId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getInvitationsByStore = (
  storeId: string
): Promise<FiraInvite[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiBusinessPrivate.get<FiraInvite[]>(
        `/v1/user-invitation-service/by-store-id/${storeId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelInvitation = (inviteId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiBusinessPrivate.put(
        `/v1/user-invitation-service/cancel-invitation/${inviteId}`
      );
      resolve(true);
    } catch (error) {
      reject(false);
    }
  });
};

export const resendInvitation = (inviteId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiBusinessPrivate.post(
        `/v1/user-invitation-service/resend-invitation/${inviteId}`
      );
      resolve(true);
    } catch (error) {
      reject(false);
    }
  });
};
