import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button, { ButtonStyle } from "../../components/Button/Button";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table/Table";
import UserProfileForm from "../../components/Forms/UserProfileForm/UserProfileForm";
import { RootState } from "../../store/Store";
import { getOwnerTeamMemberActionCreator } from "../../store/Team/TeamActions";
import { dateFormat } from "../../utils/handleDates";
import {
  FiraPermissionsStore,
  FiraTeamMember,
  RoleType,
} from "../../utils/types.d";
import styles from "./OwnerTeamDetailsView.module.scss";
import { findByIdAndStoresService } from "../../services/user";
import {
  AlertType,
  useAlert,
  usePopupAlert,
} from "../../providers/AlertProvider";
import { cleanUpdatePermissionsMessage } from "../../store/User/UserActions";
import { FiraUserResponse } from "../../types/Response";

enum OrderingType {
  status = "STATUS",
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
}

const OwnerTeamDetailsView = () => {
  const { team, brand, authentication, user } = useSelector(
    (state: RootState) => state
  );
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [storeList, setStoreList] = useState<FiraPermissionsStore[]>([]);
  const showAlert = usePopupAlert();
  const [disabledPermissions, setDisabledPermissions] =
    useState<boolean>(false);

  const [userForm, setUserForm] = useState<FiraUserResponse>();

  const handleBackButton = () => {
    navigate(-1);
  };

  const getTeamMemberInfo = () => {
    if (id) {
      dispatch(getOwnerTeamMemberActionCreator(id));
    }
  };

  const getStoresAndPermissions = async (userId: string, stores: string[]) => {
    await findByIdAndStoresService(userId, stores).then((val) => {
      const list: FiraPermissionsStore[] = [];
      val.data.permissionsStore.map((item: FiraPermissionsStore) => {
        list.push({
          storeId: item.storeId || "",
          storeName: item.storeName || "",
          role: item.role || "",
          lastSignedIn: team.currentTeamMember?.lastSignedId || "",
        });

        setStoreList(list);
      });
    });
  };

  useEffect(() => {
    if (id) getTeamMemberInfo();
  }, [id]);

  useEffect(() => {
    if (team.currentTeamMember) {
      setUserForm(team.currentTeamMember);

      if (
        team.currentTeamMember.permissionsStore !== null &&
        team.currentTeamMember.permissionsStore
      ) {
        if (brand.currentStore) {
          setStoreList(team.currentTeamMember.permissionsStore);
        } else if (
          brand.currentStore == null &&
          authentication.isAccountOwner
        ) {
          let storeStrings: string[] = [];
          brand.list.map((item) => {
            storeStrings.push(item.id);
          });
          getStoresAndPermissions(team.currentTeamMember.id, storeStrings);
        } else {
          setStoreList([]);
        }
      }
    }
  }, [team]);

  useEffect(() => {
    if (user.team.updatedUserPermissions) {
      showAlert(AlertType.success, "¡Permisos editados satisfactoriamente!");
      setDisabledPermissions(true);
      setTimeout(() => {
        dispatch(cleanUpdatePermissionsMessage());
      }, 3000);
    } else {
      setDisabledPermissions(false);
    }
  }, [user.team.updatedUserPermissions]);

  return (
    <>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={userForm ? userForm.firstName + userForm.lastName : ""}
      />
      <div className={styles.TeamDetailsWrapper}>
        <div className={styles.ProfileFormWrapper}>
          <UserProfileForm teamMemberInfo={userForm} editable={false} />
        </div>
        <div className={styles.TeamTableWrapper}>
          <TeamDetailsTable
            data={storeList}
            disabledPermissions={disabledPermissions}
          />
        </div>

        {/* <div className={styles.ButtonWrapper}>
          <Button
            width={"171px"}
            height={"32px"}
            buttonStyle={ButtonStyle.delete}
          >
            <span className={styles.DeleteButtonInner}>
              {t("views.team.details.table.deleteUserButton")}
            </span>
          </Button>
        </div> */}
      </div>
    </>
  );
};

interface TeamDetailsTableProps {
  data: FiraPermissionsStore[];
  disabledPermissions?: boolean;
}

const TeamDetailsTable = ({
  data,
  disabledPermissions,
}: TeamDetailsTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");
  const [storeList, setStoreList] = useState<FiraPermissionsStore[]>(data);

  const orderingList = [
    {
      id: 1,
      name: t("views.stores.table.status"),
      value: OrderingType.status,
    },
    {
      id: 2,
      name: "A - Z",
      value: OrderingType.descend,
    },
    {
      id: 3,
      name: "Z - A",
      value: OrderingType.ascend,
    },
  ];

  const rolType = (role: RoleType) => {
    switch (role) {
      case RoleType.ACCOUNT_OWNER:
        return t("views.stores.details.table.roles.account_owner");
      case RoleType.MANAGER:
        return t("views.stores.details.table.roles.manager");
      case RoleType.MODERATOR:
        return t("views.stores.details.table.roles.moderator");
      case RoleType.OPERATOR:
        return t("views.stores.details.table.roles.operator");
      case RoleType.PROSELLER:
        return t("views.stores.details.table.roles.proseller");
      case RoleType.SELLER:
        return t("views.stores.details.table.roles.seller");
    }
  };

  const handleFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (storeList) {
      if (keyword !== "") {
        setStoreList(
          storeList.filter((store) => {
            return store.storeName
              ?.toLowerCase()
              .includes(keyword.toLowerCase());
          })
        );
      } else {
        setStoreList(data);
      }
    }
  };

  const goToPermissions = (storeId: string) => {
    navigate(`permissions/${storeId}`);
  };

  useEffect(() => {
    setStoreList(data);
  }, [data]);

  const headerColumns = [
    {
      name: "",
      size: "16px",
    },
    {
      name: t("views.team.details.table.storeName"),
      size: "20%",
    },
    {
      name: t("views.team.details.table.role"),
      size: "100px",
    },
    {
      name: t("views.team.details.table.lastAccess"),
      size: "15%",
    },
    {
      name: "",
      size: "",
    },
    {
      name: "",
      size: "16px",
    },
  ];

  const header = (
    <TableRow>
      {headerColumns.map((item, i) => {
        return (
          <TableHead key={i} size={item.size} centerContent={i !== 1}>
            {item.name}
          </TableHead>
        );
      })}
    </TableRow>
  );

  const body = (
    <>
      {storeList &&
        storeList !== null &&
        storeList.map((item) => {
          return (
            <TableRow key={item.storeId}>
              <TableCell size="16px"></TableCell>
              <TableCell size="20%">
                <p>{item.storeName || ""}</p>
              </TableCell>
              <TableCell centerContent>
                <p>{rolType(item.role as RoleType) || ""}</p>
              </TableCell>
              <TableCell centerContent>
                <p>
                  {item.lastSignedIn
                    ? dateFormat(new Date(item.lastSignedIn))
                    : ""}
                </p>
              </TableCell>
              <TableCell RightContent>
                <Button
                  buttonStyle={
                    disabledPermissions
                      ? ButtonStyle.disabledLink
                      : ButtonStyle.transparent
                  }
                  clickHandler={() => goToPermissions(item.storeId)}
                >
                  <span
                    className={`${
                      disabledPermissions
                        ? styles.TeamTable__TableLinkDisabled
                        : styles.TeamTable__TableLink
                    }`}
                  >
                    {t("views.team.details.table.permissions")}
                  </span>
                </Button>
              </TableCell>
              <TableCell size="16px"></TableCell>
            </TableRow>
          );
        })}
    </>
  );

  return (
    <>
      <div className={styles.TeamTable}>
        <div className={styles.TeamTable__Top}>
          <div className={styles.TeamTable__Top__Title}>
            <span>{t("views.team.details.title")}</span>
          </div>
          <hr />
        </div>

        <TableHeader
          hideCheckBox={true}
          orderingList={orderingList}
          orderingClick={() => ""}
          searchInputValue={filterValue}
          searchInputChangeHandle={handleFiltering}
        />
        <Table header={header} body={body} />
      </div>
    </>
  );
};

export default OwnerTeamDetailsView;
